import { Button, Col, Form, Image, Input, Row, Spin } from "antd";
import React, { useState } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { ImageUrl } from "../../config/helper";
import { LoadingOutlined } from "@ant-design/icons";
import VivamoPayout from "../vivamo/payout";
import { useGetMeQuery } from "../../api/profile";

function RedeemCoins() {
  const navigate = useNavigate();
  // get state from route
  const { pathname } = useLocation();
  const coinType = pathname.includes("gold")
    ? "gold"
    : pathname.includes("bank")
      ? "red"
      : null;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [paymentType] = useState("bank");

  const { NODE_ENV, REACT_APP_PAYMENT_METHOD } = process.env;

  const token = useSelector((state) => state.user.userToken);

  const { data: userProfile, isLoading: isProfileLoading } = useGetMeQuery()

  // const onChange = (e) => {
  //   setPaymentType(e.target.value);
  // };

  const submit = (values) => {
    try {
      setLoading(true);
      console.log("values", values);
      let data = {
        type: coinType,
        amount: +values.amount,
        note: values.note,
        disbursementNumber: values.disbursementNumber,
        expirationDate: values.expirationDate,
      };
      Post("/coins/redeem", data, token)
        .then(async (response) => {
          setLoading(false);
          if (response?.status) {
            form.resetFields();
            if (
              coinType === "red" &&
              response?.data?.redeemRequest?.status === "APPROVED"
            ) {
              swal("System Alert", "Coins Converted Successfully", "success");
            } else {
              swal("System Alert", response?.message, "success");
            }
            navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          const { success, error, message } = err?.response?.data ?? {};
          if (!success) {
            swal(message, JSON.stringify(error), "error");
          } else {
            swal("Oops!", error, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5 className={`${coinType === "red" ? "stroke" : null}`}>
            <IoIosArrowDropleftCircle onClick={() => navigate("/checkout")} />
            <span>
              {coinType === "red"
                ? "Submit To Your Financial"
                : "SUBMIT GOLD COINS"}
            </span>
          </h5>

          <div className="about-section product-details">
            <Row gutter={20} justify={"between"}>
              <Col xs={24} md={14} lg={12}>
                <span
                  className={`card-title ${coinType === "red" ? "stroke" : null
                    }`}
                >{`Submit request to ${coinType === "red" ? "redeem" : "send"
                  } ${coinType} coins`}</span>
                <br />
                {/* <Radio.Group onChange={onChange} value={paymentType}>
                  <Radio className="text-white" value="paypal">Paypal</Radio>
                  <Radio className="text-white" value="bank">Bank</Radio>
                </Radio.Group> */}
                <br />
                <br />
                  <>
                    {paymentType === "bank" && coinType === "red" && <>{userProfile?.vivamoId || isProfileLoading ? <VivamoPayout /> : <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ minWidth: 119 }}
                      className="p-6"
                      onClick={() =>{
                        navigate("/profile-details")
                      }}
                    >
                    Fill The Bank Details 

                    </Button>
                  </Form.Item>}</>}
                    {(paymentType === "paypal" || coinType === "gold") && <Form name="basic" onFinish={submit} form={form}>
                      <Form.Item
                        name="amount"
                        rules={[
                          { required: true, message: "Please input amount!" },
                        ]}
                      >
                        <Input placeholder="Amount" />
                      </Form.Item>
                      {REACT_APP_PAYMENT_METHOD === "apt_pay" && (
                        <>
                          <Form.Item
                            name="disbursementNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please input card number!",
                              },
                            ]}
                            initialValue={
                              NODE_ENV === "development" ? "5525630721424710" : ""
                            }
                          >
                            <Input placeholder="Card Number" />
                          </Form.Item>
                          <Form.Item
                            name="expirationDate"
                            rules={[
                              {
                                required: true,
                                message: "Please input expiration date!",
                              },
                            ]}
                            initialValue={
                              NODE_ENV === "development" ? "2027-06" : ""
                            }
                          >
                            <Input placeholder="Expiration Date" />
                          </Form.Item>
                        </>
                      )}

                      <Form.Item name="note" rules={[{ required: false }]}>
                        <Input.TextArea placeholder="Notes" />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          disabled={loading}
                          type="primary"
                          htmlType="submit"
                          style={{ minWidth: 119 }}
                        >
                          {loading ? (
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="small"
                              style={{ color: "white" }}
                            />
                          ) : (
                            `${coinType === "red"
                              ? "Convert Coins"
                              : "Submit Gold Coins"
                            }`
                          )}
                        </Button>
                      </Form.Item>
                    </Form>}
                  </>
              </Col>
              <Col xs={24} md={10} lg={12}>
                <Image
                  preview={false}
                  src={ImageUrl("redeem-coin-gold.jpg")}
                  className="mb-15"
                  alt="card"
                  height={500}
                  width={500}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RedeemCoins;
