import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants/api';
import { prepareHeaders } from './cart';

export const ticketApi = createApi({
    reducerPath: 'ticketApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/ticket", prepareHeaders }),
    tagTypes: ['Ticket'],
    keepUnusedDataFor: 0,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAllTicketPackages: builder.query({
            query: () => "/package",
        }),
        getMyTicketPackages: builder.query({
            query: () => "/my",
        }),
        buyTicketPackage: builder.mutation({
            query: (id) => ({
                url: "/buy/" + id,
                method: "POST",
            }),
        }),
    }),
})

export const { useGetAllTicketPackagesQuery, useBuyTicketPackageMutation, useGetMyTicketPackagesQuery } = ticketApi
