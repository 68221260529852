import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants/api';
import { prepareHeaders } from './cart';

export const bankDetailsApi = createApi({
    reducerPath: 'bankDetialsApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/vivamo", prepareHeaders }),
    tagTypes: ['bankDetialsApi'],
    keepUnusedDataFor: 0,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({

        getPublicKey: builder.query({
            query: () => "/keys",
            transformResponse: (response) => response?.data?.publicKey,
        }),

        getCustomer: builder.query({
            query: () => "/get-customer",
            transformResponse: (response) => response?.data?.data,
        }),

        updateBankDetails: builder.mutation({
            query: (body) => ({
                url: "/update-customer",
                method: "POST",
                body,
            }),
        }),

        createPaymentIntent: builder.mutation({
            query: (body) => ({
                url: "/create-payment-intent",
                method: "POST",
                body,
            }),
        }),

        createDisbursementIntent: builder.mutation({
            query: (body) => ({
                url: "/create-disbursement-intent",
                method: "POST",
                body,
            }),
        }),

    }),
})

export const { useGetCustomerQuery, useUpdateBankDetailsMutation, useCreatePaymentIntentMutation, useCreateDisbursementIntentMutation, useGetPublicKeyQuery } = bankDetailsApi
