import { Col, Row } from "antd";
import ContactUsInfo from '../../components/contactUsInfo';
import Faqs from '../../components/faqs';

function ContactUs() {
  return (
    <div className="innerpage">
        <Row justify={"center"}>
            <Col xs={22} md={20} >
                <h5>Contact Us</h5>
                <Faqs/>
                <ContactUsInfo/>
            </Col>
        </Row>
    </div>
  )
}

export default ContactUs