import { Button, Checkbox, Col, Form, Input, Modal, Pagination, Row, Select, } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useDebouncedCallback } from "use-debounce";
import BackgroundAudio from "../../components/audioPlayer/Audio";
import CardFrame from "../../components/card";
import { Get } from "../../config/api/get";
import { PRODUCTS } from "../../config/constants/api";

function Shop({ heading, title }) {
  const { NODE_ENV } = process.env;
  const [products, setProducts] = useState(null);
  const [priceSort, setPriceSort] = useState("asc");
  const [initialLoad ,setInitialLoad] = useState(true)
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 12,
    totalDocs: 0,
    totalPages: 0,
  });

  const getProducts = useCallback(
    (pageNumber, pageSize, keyword) => {
      Get(PRODUCTS.getAllProducts, null, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
        keyword,
        price: priceSort,
      })
        .then((response) => {
          if (response?.status) {
            setProducts(response?.data?.docs);
            setPaginationConfig({
              pageNumber: response?.data?.page,
              limit: response?.data?.limit,
              totalDocs: response?.data?.totalDocs,
              totalPages: response?.data?.totalPages,
            });
          }
        })
        .catch((err) => {
          console.log("Error fetching products", err);
        });
    },
    [paginationConfig, priceSort] // Dependencies array
  );


  useEffect(() => {
    if(initialLoad){
      getProducts();
      setInitialLoad(false)
    }
  }, [getProducts,priceSort,initialLoad]);


  const handleSearch = useDebouncedCallback((e) => {
    console.log(e.target.value);
    getProducts(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      e.target.value
    );
  }, 1000);

  
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );


  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <div>Previous</div>;
    }
    if (type === "next") {
      return <div>Next</div>;
    }
    return originalElement;
  };
  const message = products
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";


  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    window.scrollTo(0, 150);
    getProducts(pageNumber, paginationConfig.limit);
  };

  const handleChange = (value) => {
    setPriceSort(value);
  };

  // Onload modal

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isReadMore, setIsReadMore] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // const onChange = (e) => {
  //   console.log(`checked = ${e.target.checked}`);
  // };

  const onFinish = (values) => {
    if (values.terms) {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="innerpage">
      <BackgroundAudio />
      <Row justify={"center"}>
        <Col xs={22} md={22}>
          <h5>Shop CARDS</h5>
          <h6 className="">You'll find it here!</h6>

        </Col>
        <Col xs={22} md={20}>
          <h4 className="text-center">{title}</h4>
          <h3 className="text-right">{heading}</h3>

          <Row justify={"center"}>

            <div className="searcbar">
              <Button type="primary" icon={<CiSearch />} />
              <Input
                placeholder="Search Product Here......."
                style={{ marginLeft: "10px", flexGrow: 1 }}
                onChange={handleSearch}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <label className="text-white pr-3">Sort by price: </label>
              <Select
                defaultValue={priceSort}
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: 'asc', label: 'Low to high' },
                  { value: 'desc', label: 'High to low' },
                ]}
              />
            </div> </Row>

          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 0px"
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={handlePageChange}
                current={parseInt(paginationConfig?.pageNumber)}
                pageSize={paginationConfig?.limit}
                total={paginationConfig?.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>

          <Row justify={"center"} gutter={20} style={{ marginBottom: "50px" }}>
            {Array.isArray(products) &&
              products.length &&
              products.map((item, index) => {
                return (
                  <Col xs={22} md={12} lg={8}>
                    <CardFrame
                      item={item}
                      products={products}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
              <p style={{ color: "white" }}>{message}</p>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={handlePageChange}
                current={parseInt(paginationConfig?.pageNumber)}
                pageSize={paginationConfig?.limit}
                total={paginationConfig?.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </Col>
      </Row>


      <Modal
        title="Terms and Conditions"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closable={false}
      >
        <div className="text-left modal-text">
          <div className={`text-content ${isReadMore ? "expanded" : ""}`}>
            <p>
              Thank you for choosing Real Money Dragon . By accessing or using our website and purchasing products or services from us, you agree to the following Terms and Conditions Please read these Terms carefully before making a purchase.
            </p>
            <p>
              These Terms apply to all purchases made through our website and govern the use of PayPal for payment processing.
            </p>
            <h4>1. Acceptance of Terms</h4>
            <p>
              By visiting our website and completing a purchase, you agree to be bound by these Terms and any other policies, agreements, or guidelines provided by us in connection with your purchase. If you do not agree with these Terms, you should not proceed with the purchase.
            </p>
            {isReadMore && (
              <div>
                <h4>2. Payment Terms</h4>
                <p>
                  All payments on this website are processed through PayPal, a secure and reliable third-party payment processor. By making a purchase on our site, you agree to the following:
                </p>
                <ul>
                  <li><strong>Currency:</strong> All transactions are processed in  (e.g., USD, EUR, etc.).</li>
                  <li><strong>Payment Information:</strong> You must provide accurate and complete information when making a purchase. Any errors or inaccuracies in your payment details may result in a failed transaction or delayed processing.</li>
                  <li><strong>Authorization:</strong> By using PayPal, you authorize us to charge the total price of your order to your PayPal account.</li>
                </ul>
                <p><strong>Note:</strong> PayPal has its own Terms of Service, and by using their service, you agree to their terms in addition to these Terms and Conditions.</p>
                <h4>3. Order Acceptance and Confirmation</h4>
                <p>After placing an order, you will receive an email confirming the details of your purchase. This email is an acknowledgment of your order, not an acceptance. We reserve the right to refuse or cancel any order at our sole discretion, including after we have received payment, if there are concerns about fraud, availability, or any other issue related to the order.</p>

                <h4>4. No Refund or Return Policy</h4>
                <ul>
                  <li><strong>Final Sale:</strong> All sales are final. We do not offer refunds or returns once a purchase is completed, and all transactions are considered final when payment is made.</li>
                  <li><strong>Exceptions:</strong> No exceptions will be made, and we will not process any refund or return requests. Please review your order carefully before completing your purchase.</li>
                </ul>
                <p><strong>Note:</strong> You acknowledge that, by completing your purchase, you understand and agree to this no-refund policy.</p>

                <h4>5. Dispute Resolution and Chargebacks</h4>
                <p>If you believe there is an issue with your purchase, we encourage you to contact us directly so we can resolve the matter as quickly as possible.</p>
                <p>If you initiate a chargeback or dispute through PayPal, we will take the necessary steps to protect our interests, including providing PayPal with proof of transaction and any related documentation. Chargebacks or payment disputes may result in suspension of your account or service.</p>
                <p><strong>Note:</strong> We strongly encourage customers to contact us directly for resolution.</p>

                <h4>6. Product Availability and Accuracy</h4>
                <p>We strive to ensure that all product information and prices are accurate. However, errors may occur. If we discover an error in the price or description of an item, we will inform you before processing the order. We reserve the right to cancel any order due to pricing or availability errors.</p>
                <h4>7. Limitation of Liability</h4>
                <p>To the fullest extent permitted by law, [Your Business Name] shall not be liable for any indirect, incidental, special, or consequential damages arising out of your use of our website, including but not limited to lost profits, loss of data, or business interruption. Our liability to you for any claim arising from your purchase shall be limited to the amount paid for the product or service.</p>
                <h4>8. Privacy and Data Protection</h4>
                <p>We value your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website and completing a purchase, you consent to the practices described in our Privacy Policy.</p>

                <h4>9. Amendments to These Terms</h4>
                <p>We reserve the right to modify, update, or revise these Terms at any time. Any changes will be effective immediately upon posting on our website, and the revised Terms will be effective from the date of posting. We encourage you to review these Terms regularly for any updates.</p>

                <h4>10. Governing Law and Dispute Resolution</h4>
                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws. Any disputes or claims arising out of or in connection with these Terms shall be resolved through [mediation/arbitration].</p>

                <h4>11. Contact Information</h4>
                <p>If you have any questions or concerns about these Terms and Conditions, please contact us</p>

                <h4>12. Acceptance of Terms</h4>
                <p>By completing a purchase, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>


              </div>
            )}
          </div>
          <span
            style={{ color: "black", cursor: "pointer", fontWeight: "bold" }}
            onClick={toggleReadMore}
          >
            {isReadMore ? "Read Less" : "Read More"}
          </span>

          <Col xs={24} md={24}>
            <Form
              name="termsForm"
              onFinish={onFinish}
              initialValues={{ terms: NODE_ENV === "development" }}
            >
              <Form.Item
                name="terms"
                valuePropName="checked" // Required for handling Checkbox values in Ant Design
                rules={[
                  { required: true, message: "You must accept the terms and conditions!" },
                ]}
                style={{
                  marginBottom: 0,
                  color: "black",
                  textAlign: "left",
                }}
              >

                <Checkbox 
                  style={{ marginBottom: 0, color: "#586D80" }}
                >
                  <p
                    className="fontFamily1"
                    style={{
                      margin: 0,
                      color: "#586D80",
                      fontSize: "12px",
                    }}
                  >
                    I accept Terms and Conditions.
                  </p>
                </Checkbox>
              </Form.Item>
              <Form.Item style={{
                textAlign: "right", // Align the button to the right
                marginBottom: 0, // Remove unnecessary spacing
              }}>
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </div>
      </Modal>
    </div>
  );
}

export default Shop;
