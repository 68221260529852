import { Button, Card, Col, Form, Input, Layout, Row, Typography } from "antd";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { Background } from "../home";
import { useState } from "react";

function ForgetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
    };
    await Post(AUTH.emailCode, data)
      .then((response) => {
        console.log(response, "#####");
        setLoading(false);
        if (response?.status) {
          navigate("/forgetpasswordcode", { state: { email: values.email } });
          swal("Success", response.message, "success");
        } else {
          setLoading(false);
          console.log("response", response);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        swal("Oops!", e?.response?.data?.message || e?.message, "error");
        console.log(":::;", e);
        setLoading(false);
      });
  };

  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };

  return (
    <>
      <Background />
      <Layout className="banner">
      <Row className="flex justify-center items-center">
        <Col  xs={22} md={12} lg={8}>
          <Card>
            <div className="authFormBox checkout-form">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <h3>FORGOT PASSWORD</h3>
                    <p>Did you forgot your password?</p>
                  <br />
                  <Form
                    layout="vertical"
                    name="basic"
                    className="loginForm"
                    labelCol={{
                      span: 0,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    // autoComplete="off"
                  >
                    <Form.Item
                    label="Enter Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please Enter Email Address",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Email Address!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email Address"
                      prefix={<FaEnvelope />}
                    />
                  </Form.Item>

                   
                    <br />

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton mainbtn"
                        disabled={loading}
                      >
                        Continue
                        {/* {loading ? "Loading..." : "Continue"} */}
                      </Button>
                    </Form.Item>
                  </Form>

                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      // textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <>
                    Back To  <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "#000",
                        }}
                      >
                       Login
                      </span>
                    </>
                  </Typography.Text>

                  
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
    </>
    
  );
}

export default ForgetPassword;
