import { Col, Row } from "antd";
import AboutSection from '../../components/AboutSection';

function AboutPage() {
  return (
    <div className='innerpage'>
        <Row justify="center" >
        <Col xs={22} md={20} xl={20}>
            <AboutSection/>
       </Col>
        </Row>
        </div>
  )
}

export default AboutPage