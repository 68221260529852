import React from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography
} from "antd";
import { FaRegUser } from "react-icons/fa";
import { GoKey } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import { Background } from "../home";
// import axios from "axios";

// const { REACT_APP_OPENCAGE_API_KEY } = process.env;
// const BLOCKED_REGIONS = ["Idaho", "Washington"];

// const validateLocation = async ({ latitude, longitude }) => {
//   try {
//     // Reverse geocode using OpenCage API (You need an API key)
//     const geoResponse = await axios.get(
//       `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${REACT_APP_OPENCAGE_API_KEY}`
//     );

//     const components = geoResponse.data.results[0].components;
//     const userRegion = components.state;

//     if (BLOCKED_REGIONS.includes(userRegion)) {
//       return { success: false, message: `Access denied from ${userRegion}` };
//     } else {
//       return { success: true, message: `Access granted from ${userRegion}` };
//     }
//   } catch (error) {
//     console.error("Geolocation Error:", error);
//     return { success: false, message: error };
//   }
// }

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { latitude, longitude } = useGeoLocation();

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    localStorage.clear();
  }, []);

  const onFinish = async (values) => {
    // if (latitude && longitude) {
    //   const response = await validateLocation({ latitude, longitude });
    //   if (!response?.success) {
    //     swal("Oops!", response.message, "error");
    //     return;
    //   }
    // } else {
    //   swal("Oops!", "Allow your location", "error");
    //   return;
    // }
    setLoading(true);
    let data = {
      email: values.email,
      password: values.password,
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data?.user?.isVerified === false) {
          navigate("/confirm-otp", { state: { email: values.email, registerBefore: true } });
          return
        }

        if (response?.data?.user?._id) {
          dispatch(
            addUser({ user: response?.data?.user, token: response?.data?.token })
          );
          navigate("/");
        } else {
          swal("Oops!", response?.message, "error");
        }



      })
      .catch((err) => {
        let message = err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Background />
      <Layout className="banner">
        <Row className="flex justify-center items-center">
          <Col xs={22} md={12} lg={8}>
            <Card>
              <div className="authFormBox checkout-form">
                <Row style={{ width: "100%", justifyContent: "center" }}>
                  <Col xs={20} md={20} className="formWrap">
                    <h3>SignIn</h3>
                    <p>Enter Your Credentials To Log In To The Platform</p>

                    <br />
                    <Form
                      layout="vertical"
                      name="basic"
                      className="loginForm"
                      labelCol={{
                        span: 0,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please input valid email!",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Email Address"
                          className="AuthFormInput"
                          prefix={<FaRegUser />}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter Password"
                          className="AuthFormInput"
                          prefix={<GoKey />}
                        />
                      </Form.Item>
                      <Row>
                        <Col xs={24} md={12}>
                          <Form.Item
                            name="remember"
                            valuePropName="checked"
                            style={{
                              marginBottom: 0,
                              color: "black",
                              textAlign: "left",
                            }}
                          >
                            <Checkbox
                              style={{ marginBottom: 0, color: "#586D80" }}
                            >
                              <p
                                className="fontFamily1"
                                style={{
                                  margin: 0,
                                  color: "#586D80",
                                  fontSize: "12px",
                                }}
                              >
                                Remember Me
                              </p>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Button
                            type="link"
                            style={{
                              float: "right",
                              fontWeight: "bold",
                              textDecoration: "underline",
                              fontSize: "12px",
                            }}
                            onClick={() => navigate("/forgetpassword")}
                          >
                            <p
                              style={{
                                margin: 0,
                                color: "black",
                                fontSize: "12px",
                              }}
                            >
                              Forgot Password?
                            </p>
                          </Button>
                        </Col>
                      </Row>
                      <br />

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="loginButton mainbtn"
                        >
                          {/* Login */}
                          {loading ? "Loading..." : "Login"}
                        </Button>
                      </Form.Item>
                    </Form>

                    <div className="divider">
                      <span className="divider-text">OR</span>
                    </div>

                    {/* <div className="playstore-icons">
                      <div>
                        <Image
                          preview={false}
                          src={ImageUrl("google-play-btn.png")}
                          className="mt-15 mb-15"
                        />
                        <span>Google</span>
                      </div>
                      <div>
                        <Image
                          preview={false}
                          src={ImageUrl("apple-btn.png")}
                          className="mt-15 mb-15"
                        />
                        <span>Apple</span>
                      </div>
                      <div>
                        <Image
                          preview={false}
                          src={ImageUrl("facebook.png")}
                          className="mt-15 mb-15"
                        />
                        <span>Facebook</span>
                      </div>
                    </div> */}

                    <Typography.Text
                      style={{
                        fontSize: "14px",
                        color: "black",
                        // textAlign: "left",
                        marginTop: 0,
                        marginBottom: 30,
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      <>
                        Don&apos;t Have An Account?{" "}
                        <span
                          onClick={() => navigate("/signup")}
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            color: "#000",
                          }}
                        >
                          {" "}
                          REGISTER NOW
                        </span>
                      </>
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default Login;
