import { Col, Row } from "antd";
import CarouselSlider from "../carousel";

function ProductSlider({ heading, title, products }) {
  return (
    <div className="product-slider">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h4 className="text-center">{title}</h4>
          <h3 className="text-center">{heading}</h3>
        </Col>
      </Row>
      <CarouselSlider products={products}/>
    </div>
  );
}

export default ProductSlider;
