import { Button, Image } from "antd";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { getGiveawayStatus, giveawayName } from '../../views/giveAways/giveawayDetails';

const images = ["purple-giveaway.png", "blue-giveaway.png", "red-giveaway.png"];

function randomImage() {
  const randomIndex = Math.floor(Math.random() * images.length);
  return ImageUrl(images[randomIndex]);
}

function GiveAwayCard({ item }) {
  const navigate = useNavigate();
  const giveawayStatus = getGiveawayStatus(item?.startTime, item?.endTime);

  // const { NODE_ENV } = process.env;
  // const timeZone = NODE_ENV === "development" ? "local" : "local";
  // const dateFormat = "Do MMMM YYYY, h:mm:ss A";
  // const startTime = moment(item?.startTime)?.[timeZone]()?.format(dateFormat);
  // const endTime = moment(item?.endTime)?.[timeZone]()?.format(dateFormat);
  let hour = moment(item?.startTime).get('hour');
  if (hour < 10) {
    hour = `0${hour}`;
  }
  const code = `G-${hour}`;
  const time = giveawayName[code]?.split('- ')[1];
  return (
    <div className='give-away-card' style={{ opacity: giveawayStatus === 'Active' ? 1 : 0.6 }}>
      <div className='giveaway-top-area'>
        <div className='ribbon'>Draw Today AT {time} &nbsp; </div>
        <Image preview={false} src={randomImage()} alt="" />
      </div>
      <h4>Dreamy Imaginations</h4>
      <h5><Image preview={false} src={ImageUrl("batch-icon.png")} />Price Worth - {item?.prize} Gold Coins</h5>
      <Button className='mainbtn' onClick={() => { navigate(`/give-away/${item?._id}`) }}>ENTER IN THIS GIVEAWAY</Button>
    </div>
  )
}

export default GiveAwayCard