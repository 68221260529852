import React, { useEffect, useMemo, useRef, useState } from "react";

// Icons
import { AiOutlineSend } from "react-icons/ai";
import { BsEmojiSmile } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { PiDotsThreeOutlineThin, PiWarningCircleLight } from "react-icons/pi";
import { RiEditBoxLine } from "react-icons/ri";
import swal from "sweetalert";
import { ImAttachment } from "react-icons/im";


// Api Imports
import {
  useCreateChatMutation,
  useGetAllChatMsgQuery,
  useGetAllUserQuery,
  useGetMyChatsQuery,
  useSendImageMutation
} from "./chatApiSlice";

// Emoji Mart
import Picker from "@emoji-mart/react";

// modal for searching new User
import { Modal } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { UPLOADS_URL } from "../../config/constants/api";
import useSocket from "./useSocket";
import moment from "moment";

const msgTime = (time) => {
  try {
    const now = moment();
    const messageTime = moment(time);
    const formattedTime = now.isSame(messageTime, 'day')
      ? messageTime.format('h:mm A')  // Show only time if today
      : messageTime.format('MMM D, h:mm A'); // Show date and time if not today
    return formattedTime
  } catch (error) {
    console.log(error)
  }
}

// ChatBubble component
const ChatBubble = ({ message, isSent, myImage, selectedUserAvatar, createdAt ,ImageContent}) => (
  <div
    className={`flex ${isSent ? "justify-end" : "justify-start"} space-x-3 `}
  >
    {!isSent && (
      <div className="flex-shrink-0 ">
        <img
          // src={selectedUserAvatar}
          src={selectedUserAvatar || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
          alt="User Avatar"
          className="w-8 h-8 rounded-full"
        />
      </div>
    )}

    <div
      className={`max-w-72 sm:max-w-96 p-3 ml-28 rounded-lg font-semibold flex flex-col gap-2  ${isSent
        ? "bg-[#1870e3] text-white rounded-br-none "
        : "bg-gray-300 text-black rounded-bl-none"
        } whitespace-pre-wrap break-words`}
    >

      {/* Conditionally render Image is exist */}
      {ImageContent && (
        <img
          src={ImageContent}
          alt="Preview"
          className="w-40 h-40 object-cover rounded-lg border"
        />
      )}



      <p>{message}</p>
      <p className={`text-xs ${isSent
        ? "self-end"
        : "self-start"
        } `}>{createdAt}</p>
    </div>
    {isSent && (
      <div className="flex-shrink-0">
        <img
          // src={`https://xsgames.co/randomusers/assets/avatars/pixel/${
          //   Math.floor(Math.random() * 50) + 1
          // }.jpg`}
          src={myImage || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
          alt="User Avatar"
          className="w-8 h-8 rounded-full"
        />
      </div>
    )}
  </div>
);

// Main Chat Component
const ChatComponent = () => {
  const user = useSelector((state) => state.user.userData);
  const { _id: userId, image: myImage } = user;
  const [searchValue, setSearchValue] = useState("");
  const { data: myChats, refetch: refetchMyChats } = useGetMyChatsQuery();

  const [selectedChat, setSelectedChat] = useState(myChats ? myChats[0] : null); // comes from API

  const { messages: socketMsg, sendMessage } = useSocket({
    chatId: selectedChat?._id,
  });

  const { data: allUsers, refetch: refetchUsers } = useGetAllUserQuery();

  const filteredUsers = useMemo(() => {
    return allUsers?.filter((user) => {
      const searchQuery = searchValue.toLowerCase();
      return (
        user.firstName.toLowerCase().startsWith(searchQuery) ||
        user.lastName.toLowerCase().startsWith(searchQuery) ||
        user.email.toLowerCase().startsWith(searchQuery)
      );
    });
  }, [allUsers, searchValue]);

  // Create Chat Mutation
  const [createChat, { data: createdChatData }] = useCreateChatMutation();
  const [sendImage, { isLoading: isSendingImage }] = useSendImageMutation();

  // const [sendMsg, { data: sentMsg }] = useSendMessageMutation();

  const { data: allMsgs = [], isLoading } =
    useGetAllChatMsgQuery(selectedChat?._id);
  const [content, setContent] = useState("");

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const inputImage = useRef(null)

  const [isPickerVisible, setIsPickerVisible] = useState(false);
  // const [isAttachmentVisible, setIsAttachmentVisible] = useState(false);


  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [image, setImage] = useState(null);


  const handleImageChange = (e) => {
    
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set preview
        setContent(file.name);
      };
      reader.readAsDataURL(file);
      
    }

    // setIsAttachmentVisible(false)
    // setContent(inputImage?.current?.files[0]?.name)
  };

  function handleCancelImage() {
    setImage(null)
    setContent("")
  }

  const showModal = () => {
    refetchUsers();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEmojiSelect = (emoji) => {
    setContent(content + " " + emoji.native);
  };

  const handleSendMessage = async () => {

    if (isSendingImage) return;

    const newMessage = { chatId: selectedChat?._id, sender: user };

    const formData = new FormData();

    if (image) {

      formData.append("image", inputImage?.current?.files[0]);
      formData.append("chatId", selectedChat?._id);

      const res = await sendImage(formData);

      if (!res?.data?.status) {
        swal(
          "Error!",
          res.response?.data?.message || res?.data?.message,
          "error"
        );

        return;
      }


      // Reset input field and image
      if (inputImage?.current) {
        inputImage.current.value = "";
      }

      newMessage.image = res?.data?.data?.image;
      sendMessage({ ...newMessage, image: res?.data?.data?.image });
    }

    else {
      if(!content) return 
      sendMessage({ ...newMessage, content });
    }


    setContent("");
    setImage(null)
    inputRef?.current?.focus();

  };

  function searchUser(e) {
    const { value } = e.target;
    setSearchValue(value);
  }

  useEffect(() => {
    // Scroll to the last message
    if (messagesEndRef?.current) {
      // Ensure the scroll only happens inside the message container
      const container = messagesEndRef.current.closest('.message-container'); // Modify the selector if necessary

      // Prevent the whole page from scrolling (target the container instead)
      container?.scrollTo({
        top: container.scrollHeight, // Scroll to the bottom of the container
        behavior: 'smooth',
      });
    }
    // Active the Input field
    inputRef?.current?.focus();

  }, [selectedChat, socketMsg, allMsgs, image ]);

  useEffect(() => {
    refetchMyChats();
  }, [socketMsg, refetchMyChats]);

  async function createNewChat(id) {
    const {
      data: {
        status,
        data: {
          chat: { _id },
        },
      },
    } = await createChat(id);
    if (status) {
      let { data } = await refetchMyChats();
      setSelectedChat(data.find((chat) => chat._id === _id));
    }
  }

  const [openChat, setOpenChat] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // default check for mobile

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update state based on width
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div className="flex flex-col lg:flex-row  items-center mt-0 md:mt-0 xl:mt-0 mx-2 md:gap-[1px] w-[97vw] lg:w-auto">
        <Modal
          className="w-full"
          title="Find User"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <input
            onChange={searchUser}
            className="w-full outline-none border-b-[1px] text-xl font-semibold border-black"
            placeholder="Type name to search"
            type="text"
            value={searchValue}
          />
          <div className="mt-4 overflow-y-auto h-[500px]">
            {filteredUsers?.length > 0 && searchValue ? (
              filteredUsers.map((val, i) => (
                <div
                  className="flex items-center gap-5  lg:hover:bg-gray-200 rounded-md w-full p-2"
                  onClick={() => {
                    createNewChat(val._id);
                    setIsModalOpen(false);

                  }}
                  key={i}
                >
                  <img
                    className="rounded-full h-10 w-10"
                    src={user?.image ? UPLOADS_URL + user?.image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
                    alt="user"
                  />
                  <div className="flex flex-col gap-0">
                    <h1 className={`whitespace-nowrap ${val?.isAdmin ? "text-blue-600" : ""}`}>
                      {`${val?.firstName + " " + val?.lastName} ${val?.isAdmin ? "(Admin)" : ""}`}
                    </h1>
                  </div>
                </div>
              ))
            ) : (
              <>
                {searchValue && (<p>No User Found!</p>)}
              </>
            )}
          </div>
        </Modal>
        {/*  Side Bar */}
        <div className={`${isMobile && openChat ? "hidden" : "block"} bg-white text-black h-[800px] w-full md:h-[300px] md:w-[600px] lg:h-[500px]  md:p-1  lg:w-[300px] xl:h-[450px] 2xl:h-[620px]  rounded-md overflow-hidden `}>
          <div className="flex items-center justify-between px-2 my-1">

            <PiDotsThreeOutlineThin
              className="hover:text-gray-500 "
              size={24}
            />


            <h1 className="text-2xl p-4">
              Chat (<span>{myChats?.length}</span>)
            </h1>
            <div className="flex items-center gap-2">

              <RiEditBoxLine
                className="hover:text-gray-500 "
                onClick={showModal}
                size={30}
              />
            </div>
          </div>
          <div className="w-full h-[1px] bg-black"></div>
          {/* List of user */}
          <div className="flex flex-col items-start cursor-pointer md:ml-0 lg:ml-0 gap-3 lg:gap-3 pb-44 pr-3 md:pr-4 lg:pr-0  mt-4 overflow-y-auto h-full scrollbar-hidden ">
            {myChats?.length > 0 ? (
              myChats?.map((val, i) => {
                return (
                  <div
                    className={`flex items-center gap-5 mx-0 lg:mx-0 md:pr-14 lg:pr-0 lg:hover:bg-gray-300 w-full p-2 rounded-lg ${selectedChat?._id === val._id && !isMobile ? "bg-gray-300" : ""
                      }
                  ${val?.unreadCount > 0 && "bg-blue-200"}
                  `}
                    onClick={() => {
                      if (isMobile) {
                        setOpenChat(true)
                        setSelectedChat(val)

                      } else {
                        setSelectedChat(val)
                      }
                    }}
                    key={i}
                  >
                    <div className="relative">
                      <img
                        className="rounded-full h-14 w-14  md:h-10 md:w-10"
                        src={val?.users[1]?.image ? UPLOADS_URL + val.users[1].image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
                        alt="val"
                      />
                      <div className={`
                  ${val?.unreadCount > 0 ? "flex" : "hidden"}
                  w-6 h-6 absolute top-[-8px] right-[-8px] bg-red-600 text-white rounded-full  justify-center items-center text-xs font-extrabold`}>{val?.unreadCount}</div>
                    </div>
                    <div className="flex flex-col gap-0">
                      <h1 className="whitespace-nowrap">{val?.name}</h1>
                      <h1 className="whitespace-nowrap text-[9px]">{val?.lastMsg ? val.lastMsg.slice(0, 30) : ""}</h1>
                      <p className="text-xs">{val.status}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* Chat Component */}
        <div className={`${isMobile && !openChat ? "hidden" : "flex "} flex-col w-full relative h-[700px] md:w-[600px] md:h-[600px] lg:h-[500px] lg:w-[600px]xl:w-[700px]  xl:h-[450px] 2xl:h-[620px]  mx-auto bg-white text-black rounded-lg shadow-lg`}>
          {/* Chat Header */}

          {selectedChat && (
            <div className="bg-white text-black box-border  border-t-[0.1px] border-b-[0.1px] border-t-white border-b-black  p-4 rounded-t-lg flex justify-between items-center">
              <div className="font-semibold flex items-center relative gap-2">
                <div className={`${!isMobile || !openChat ? "hidden" : "block absolute top-2 left-[-8px]"} text-white`}><FaArrowLeft color="black" onClick={() => setOpenChat(false)} size={24} /></div>
                <img
                  className="rounded-full ml-7 md:ml-0 h-10 w-10"
                  src={selectedChat?.users[1]?.image ? UPLOADS_URL + selectedChat.users[1].image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
                  alt="avatar"
                />
                <div className="flex flex-col justify-center items-start gap-0">
                  <p>{selectedChat?.name}</p>

                  {/* Code for active and inActive */}
                  {/* <did className="text-xs flex items-center gap-1">
                  <div
                    className={`${
                      selectedUser.status === "active" ? "block" : "hidden"
                    } w-1 h-1 bg-green-400`}
                  ></div>{" "}
                  {selectedUser.status === "active" && "Active"}
                </did> */}
                </div>
              </div>

              <div className="flex justify-center items-center gap-5">
                {/* <FiPhone className="hover:text-gray-500" size={24} />
              <IoVideocamOutline className="hover:text-gray-500" size={24} /> */}
                <PiWarningCircleLight className="hover:text-gray-500" size={24} />
              </div>
            </div>
          )}

          {/* Messages Section */}
          <div className={`flex-1 p-4 overflow-y-auto scrollbar-hidden space-y-4 message-container ${image && "mb-44"}`}>
            {(allMsgs?.length > 0 || socketMsg?.length > 0) ? (
              [...allMsgs, ...socketMsg].map((val, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <ChatBubble
                    message={val?.content}
                    isSent={val?.sender?._id === userId}
                    ImageContent={val?.image && UPLOADS_URL + val.image}
                    createdAt={msgTime(val?.createdAt)}
                    myImage={user?.image && UPLOADS_URL + myImage}
                    selectedUserAvatar={selectedChat?.users[0]?.image && UPLOADS_URL + selectedChat.users[0].image}
                  />
                  <div
                    className={`w-full mt-1 flex ${val?.sender === "user" ? "justify-end" : "justify-start"
                      } space-x-3 text-xs text-gray-500`}
                  >
                  </div>
                </div>
              ))
            ) : <>
              {isLoading ?
                <div className="flex gap-2 justify-center items-center">
                  <div className="w-8 h-8 border-4 border-t-4 border-[#1870e3] border-dashed rounded-full animate-spin"></div>
                  <div className="font-semibold"> Loading Chats</div>
                </div>
                :
                <>
                  {selectedChat ? <>
                    <p className="text-center text-gray-500 text-xl">No conversation</p>
                  </> : <>
                    <p className="text-center text-gray-500 text-xl">Select Chat to Start the conversation</p>
                  </>

                  }
                </>
              }
            </>}



            {/* Image Preview */}
            {image && (
              <div className="absolute bottom-20 right-3">
                <>
                  <MdCancel
                    size={24}
                    onClick={handleCancelImage}
                  />
                  <img
                    src={image}
                    alt="Preview"
                    className="w-40 h-40 object-cover rounded-lg border"
                  />
                </>
              </div>
            )}

            <div ref={messagesEndRef} />
          </div>

          {/* Input Section */}
          {selectedChat && (
            <div className="p-4 bg-white text-black rounded-b-lg flex items-center space-x-4">
              <input
                ref={inputRef}
                type="text"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Type your message"
                className="w-full p-2 bg-white rounded-lg border text-black border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && content.trim()) {
                    e.preventDefault();
                    handleSendMessage();
                    // sendMessage();
                  }
                }}
              />

              {/* EMOJI PICKER */}
              <div className="relative">
                {isPickerVisible && (
                  <div
                    className={`${isPickerVisible ? "block" : "hidden"
                      } h-52 w-52 absolute bottom-72 right-24`}
                  >
                    <MdCancel
                      size={24}
                      onClick={() => setIsPickerVisible(false)}
                    />
                    <Picker
                      data={createdChatData}
                      onEmojiSelect={handleEmojiSelect}
                    />
                  </div>
                )}




                {/* {isAttachmentVisible && (
                  <div
                    className={`${isAttachmentVisible ? "block" : "hidden"
                      } h-28 w-24 flex flex-col justify-end absolute bg-slate-800 text-white rounded-lg p-1 bottom-12 right-9`}
                  >
                    <MdCancel
                      className="absolute right-1 top-1 "
                      size={24}
                      onClick={() => setIsAttachmentVisible(false)}
                    />
                    <div className="text-center mb-1">
                      <p
                        onClick={() => inputImage?.current?.click()}
                        className="w-full hover:bg-blue-400 font-semibold cursor-pointer">Image</p>
                    </div>
                  </div>
                )} */}

                {/* input type file for taking images */}
                <input
                  type="file"
                  className="hidden"
                  ref={inputImage}
                  onChange={handleImageChange}
                />


                <div className="flex  items-center gap-2">
                  
                <ImAttachment
                  className="hover:text-gray-500 "
                  onClick={() => {
                    // setIsAttachmentVisible(!isAttachmentVisible)
                    inputImage?.current?.click()
                  }}
                  size={30}
                />

                <BsEmojiSmile
                  className="hover:text-gray-500 "
                  onClick={() => setIsPickerVisible(!isPickerVisible)}
                  size={30}
                />
                </div>


              </div>



              {isSendingImage ?
                <div className="flex gap-2 justify-center items-center">
                  <div className="w-8 h-8 border-4 border-t-4 border-[#1870e3] border-dashed rounded-full animate-spin"></div>
                </div>
                :
                <>
        
              {/* Send Icon */}
              <AiOutlineSend
                size={36}
                onClick={handleSendMessage}
                className={` hover:text-gray-500 cursor-pointer ${content && "text-blue-400"}  `}
              />
                </>
              }






            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatComponent;