import { bankDetailsApi } from '../api/vivamo';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore } from 'redux-persist';
import { aptPayApi } from '../api/aptPay';
import { cartApi } from '../api/cart';
import { giveawayApi } from '../api/giveaway';
import { postApi } from '../api/post';
import { profileApi } from '../api/profile';
import { ticketApi } from '../api/ticket';
import { chatApi } from "../components/chat/chatApiSlice";
import userReducer from './slice/authSlice';
import { orderApi } from '../api/orderApi';
import { stripeApi } from '../api/stripe';


const rootReducer = combineReducers({
  user: userReducer,
  [cartApi.reducerPath]: cartApi.reducer,
  [ticketApi.reducerPath]: ticketApi.reducer,
  [giveawayApi.reducerPath]: giveawayApi.reducer,
  [aptPayApi.reducerPath]: aptPayApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [bankDetailsApi.reducerPath]: bankDetailsApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,

})

// const persistConfig = {
//   key: 'real_money_dragon_user',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
const persistedReducer = rootReducer

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cartApi.middleware).concat(ticketApi.middleware).concat(giveawayApi.middleware).concat(aptPayApi.middleware).concat(profileApi.middleware).concat(postApi.middleware).concat(chatApi.middleware).concat(bankDetailsApi.middleware).concat(orderApi.middleware).concat(stripeApi.middleware),
})

export const persistor = persistStore(store)