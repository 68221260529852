import { BASE_URL } from "../config/constants/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "./cart";

export const orderApi = createApi({
    reducerPath: "orderApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/order`,
        credentials: "include",
        prepareHeaders
    }),


    endpoints: (builder) => ({
        placeOrder: builder.mutation({
            query: (orderData) => {
                console.log(orderData, "<=====");
                return {
                    url: "",
                    method: "POST",
                    body: orderData,
                };
            },
        }),

        getMyOrder: builder.query({
            query: () => "/getMyOrders",
            transformResponse: (res) => res?.data?.docs,
        }),

        getOrderById: builder.query({
            query: (id) => `/${id}`,
            transformResponse: (res) => res?.order,
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    usePlaceOrderMutation,
    useGetMyOrderQuery,
    useGetOrderByIdQuery,
} = orderApi;
