import { BASE_URL } from '../config/constants/api';
// import { LOCAL_STORAGE_KEY } from "@/constants/constant";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const stripeApi = createApi({
  reducerPath: "stripeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/stripe`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getPublishableKey: builder.query({
      query: () => "/keys",
      transformResponse: (response) => response?.data?.publishableKey,
    }),

    createPaymentIntent: builder.mutation({
      query: ({ amount }) => ({
        url: "/create-payment-intent",
        method: "POST",
        body: { amount },
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetPublishableKeyQuery, useCreatePaymentIntentMutation } = stripeApi;
