"use client";
import { useGetMyOrderQuery } from "../../api/orderApi";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const MyOrder = () => {
    const { data, isFetching } = useGetMyOrderQuery({});

    const navigate = useNavigate()

    return (
        <div className=" p-8 rounded-md w-full h-screen">
            <div className="flex items-center justify-between pb-6 pt-28">
                <div>
                    <h2 className="text-white text-xs md:text-xl font-semibold">My Orders</h2>
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex bg-gray-50 items-center p-2 rounded-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <input
                            className="bg-gray-50 outline-none ml-1 block"
                            type="text"
                            placeholder="search..."
                        />
                    </div>
                    <div className="lg:ml-0 ml-10 space-x-8">
                        {/* <button className="bg-[#efbd61] hover:bg-[#e3b050] px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">
              New Report
            </button> */}
                        {/* <button className="bg-[#efbd61] hover:bg-[#e3b050] px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">
              Create
            </button> */}
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto -mx-4 sm:-mx-8 px-4 sm:px-8 py-4">
                <div className="inline-block min-h-[400px] bg-white relative  min-w-full shadow rounded-lg overflow-hidden">
                    {isFetching ? (
                        <div className="flex justify-center min-h-10 text-white">
                            {/* <Spinner /> */}
                            Loading ...
                        </div>
                    ) : (
                        <>
                            <table className="min-w-full leading-normal ">
                                <thead>
                                    <tr>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Name
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            email
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Created at
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Payment Method
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Total Price
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Status
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 ? data?.map((product, index) => (
                                        <tr key={index}>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="flex items-center ">
                                                    <div className="">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {product?.firstName} {product?.lastName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {product?.email}
                                                </p>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {moment(product?.createdAt).format(
                                                        "MMMM Do YYYY, h:mm:ss a"
                                                    )}
                                                </p>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {product?.paymentMethod ? product.paymentMethod : "-"}
                                                </p>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {product?.totalPrice}
                                                </p>
                                            </td>

                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <span
                                                    className={`relative inline-block  py-1 font-semibold text-${product?.paymentStatus === "paid" ? "green" : product?.paymentStatus === "unpaid" ? "red" : "orange"}-900 leading-tight`}
                                                >
                                                    <span
                                                        aria-hidden
                                                        className={`absolute inset-0 bg-${product?.paymentStatus === "paid" ? "green" : product?.paymentStatus === "unpaid" ? "red" : "orange"}-200 opacity-50 rounded-full`}
                                                    ></span>
                                                    <span className="relative">
                                                        {product.paymentStatus}
                                                    </span>
                                                </span>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div
                                                    onClick={() => {
                                                        navigate(`/my-orders/order-details/${product?._id}`)
                                                    }}
                                                    className="text-gray-900 whitespace-no-wrap cursor-pointer"
                                                >
                                                    <MdEdit />
                                                </div>
                                            </td>
                                        </tr>
                                    ))


                                        : (
                                            <tr>
                                                <td colSpan="7" className="px-5 py-5 text-center">
                                                    No Orders Found
                                                </td>
                                            </tr>
                                        )

                                    }
                                </tbody>
                            </table>
                        </>
                    )}

                    <div className={`px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between   ${data?.length <= 0 || !data ? "absolute bottom-1 left-10" : ""}`}>
                        <span className="text-xs xs:text-sm text-gray-900">
                            Showing 1 to 4 of 50

                        </span>
                        <div className="inline-flex mt-2 xs:mt-0">
                            <button className="text-sm text-indigo-50 transition duration-150 bg-[#efbd61] hover:bg-[#e3b050]font-semibold py-2 px-4 rounded-l">
                                Prev
                            </button>
                            &nbsp; &nbsp;
                            <button
                                className="text-sm text-indigo-50 transition duration-150 bg-[#efbd61] hover:bg-[#e3b050] font-semibold py-2 px-4 rounded-r">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyOrder;
