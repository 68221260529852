import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../config/constants/api';

function useFetch(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const token = useSelector((state) => state.user.userToken);

    const fetchData = useCallback(() => {
        setLoading('loading...');
        setData(null);
        setError(null);
        const source = axios.CancelToken.source();
        axios.get(BASE_URL + url, {
            cancelToken: source.token,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                setLoading(false);
                setData(res.data);
            })
            .catch(err => {
                setLoading(false);
                setError('An error occurred. Awkward..');
            });
        return () => {
            source.cancel();
        };
    }, [url, token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, loading, error, refetch: fetchData };
}

export default useFetch;