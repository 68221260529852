import { Button, Col, Row, Spin } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import QuantitySelector from "../../components/quantitySelector";
import { Post } from "../../config/api/post";

function Tip({ close }) {
  const token = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const handleSelectedQuantityChange = (newQuantity) => {
    setSelectedQuantity(newQuantity);
  };

  const sendTip = () => {
    setIsLoading(true);

    let data = { amount: selectedQuantity };
    Post("/tip", data, token)
      .then((response) => {
        // console.log(response)
        close()
        setIsLoading(false);
        if (response?.status) {

          swal("System Alert", response?.message, "success");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };

  return (
    <Row justify={"center"}>
      <Col>
        <Row gutter={20}>
          <Col>
            <h3
              style={{ fontFamily: "monospace", textTransform: "uppercase" }}
              className="text-center mb-5 mt-1 text-white font-extrabold"
            >
              Tip
            </h3>
            <div className="quantity ">
              <QuantitySelector
                value={selectedQuantity}
                onChange={handleSelectedQuantityChange}
              />
            </div>
            <Button className="mainbtn" onClick={sendTip} disabled={isLoading}>
              {!isLoading ? "Send Tip" : <Spin />}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Tip;
