import { Col, Image, Row } from "antd";
import { FaHeadphones } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { ImageUrl } from "../../config/helper";

function ContactUsInfo() {
  // const navigate = useNavigate();
  // const isMobile = useMediaQuery("(max-width: 767px)")
  return (
    <div className="about-section">
      <Row gutter={20}>
        <Col xs={24} md={10} lg={8}>
          <div
            className="wow fadeInUp"
            data-wow-duration="2.5s"
            data-wow-delay="0.8s"
          >
            <Image
              preview={false}
              src={ImageUrl("about-image1.png")}
              alt="card"
            />
          </div>
        </Col>
        <Col xs={24} md={14} lg={16}>
          <h3>Contact Info</h3>
          <p>
            <label>
              <FaHeadphones /> CS Phone number:{" "}
              <a href="tel:2396860033"> 239-686-0033 </a> (LAND LINE)
            </label>

            <label>
              <HiOutlineMail /> *
              <a href="mailto:Support@Realmoneydragon.io">
                Support@Realmoneydragon.io{" "}
              </a>{" "}
              (Any questions or concerns a customer may have)
            </label>

            <label>
              <HiOutlineMail /> *
              <a href="mailto:Social@Realmoneydragon.io">
                Social@Realmoneydragon.io
              </a>{" "}
              (any social media platforms)
            </label>
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default ContactUsInfo;
