import { LoadingOutlined } from '@ant-design/icons';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Button, Spin } from "antd";
import React, { memo, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { BASE_URL } from '../../config/constants/api';
import styles from './paypal.module.css';

function Message({ content }) {
    return <p>{content}</p>;
}

function PaypalCard({ totalPrice, localOrderData, clientId }) {
    const [{ isPending,isRejected}] = usePayPalScriptReducer();
    const token = useSelector((state) => state.user.userToken);

    const CompleteOrder = async (paypalOrderData) => {
        try {
            Post(`/order/${localOrderData?._id}/pay`, { paypalOrderData }, token)
                .then((response) => {
                    if (response?.success) {
                        navigate("/payment-success", { state: { orderId: response?.order?._id } })
                    }
                })
                .catch((err) => {
                    let message = err?.response?.data?.message || err?.message;
                    console.log(":::;", err);
                    if (message) swal("Oops!", message, "error");
                });
        } catch (error) {
            console.error(error);
            setMessage(
                `Sorry, your transaction could not be processed...${error}`
            );
        }
    }

    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    async function createOrder() {
        try {
            const response = await fetch(`${BASE_URL}/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    cart: [
                        {
                            id: "YOUR_PRODUCT_ID",
                            quantity: parseInt(totalPrice).toString(),
                        },
                    ],
                    orderId: localOrderData?._id,
                }),
            });

            const orderData = await response.json();
            if (orderData.id) {
                return orderData.id;
            } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error(error);
            setMessage(
                `Could not initiate PayPal Checkout...${error}`
            );
        }
    }

    async function onApprove(data, actions) {
        try {
            const response = await fetch(
                `${BASE_URL}/orders/${data.orderID}/capture`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                }
            );

            const orderData = await response.json();
            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
            } else if (errorDetail) {
                throw new Error(
                    `${errorDetail.description} (${orderData.debug_id})`
                );
            } else {
                const transaction =
                    orderData.purchase_units[0].payments
                        .captures[0];
                setMessage(
                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                );
                console.log(
                    "Capture result",
                    orderData,
                    JSON.stringify(orderData, null, 2)
                );
                if (orderData?.id && orderData?.status === "COMPLETED") {
                    CompleteOrder(orderData);
                }
            }
        } catch (error) {
            console.error(error);
            setMessage(
                `Sorry, your transaction could not be processed...${error}`
            );
        }
    }

    async function onError(err) {
        console.error('PayPal Checkout Error:', err);
    }

    return (
        <div>
            {(!isPending && (isRejected || !clientId)) && <MyPayButton />}
            {isPending ?
                <div style={{ height: 210, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                </div>
                : <div>
                    <PayPalButtons
                        forceReRender={[totalPrice, localOrderData, clientId]}
                        fundingSource="paypal"
                        style={{
                            shape: "rect",
                            layout: "vertical",
                            color: "gold",
                            label: "paypal",
                        }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                    />
                    <br />
                    <PayPalButtons
                        forceReRender={[totalPrice, localOrderData, clientId]}
                        fundingSource="card"
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                    />
                </div>}
            <Message content={message} />
        </div>

    );
}

export default memo(PaypalCard);

export const MyPayButton = () => {
    const clickPayButton = () => {
        window.location.reload();
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '45%' }}><Button onClick={clickPayButton} className={styles.paybtn}>Pay</Button></div>
    )
}