import { Button, Card, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import swal from "sweetalert";
import { useGetPayeeQuery, useUpdatePayeeMutation } from "../../api/aptPay";

function PayeeInfo() {
  const { data: payeeData, refetch } = useGetPayeeQuery();
  const [updatePayee, { isLoading: isUpdating }] = useUpdatePayeeMutation();
  const [editMode, setEditMode] = useState(false);

  const onFinish = (values) => {
    const dataToSend = {
      first_name: values.first_name,
      last_name: values.last_name,
      country: values.country,
      dateOfBirth: values.dateOfBirth,
      clientId: values.clientId,
      email: values.email,
    };
    
    
    updatePayee(dataToSend).then((res) => {
      if (res?.data?.status) {
        swal("Success", res?.data?.message, "success");
        setEditMode(false);
        refetch();
      } else {
        swal("Error", res?.error?.data?.status[0]?.msg || "An error occured", "error");
      }
    }).catch((err) => {
      swal("Error", err?.data?.message || "An error occured", "error");
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row justify="center">
        <Col xs={24} md={24} xl={24}>
          <h4 style={{ textAlign: "center", color: "#fff", margin: "0 0 10px" }}>
            Payee Information
          </h4>
          <Card>
            <Row style={{ padding: "20px" }}>
              <Col xs={24} md={24}>
                <Row style={{ justifyContent: "center", textAlign: "center" }}>
                  <Col xs={24} md={20} lg={11}>
                    <Form
                      layout="vertical"
                      name="basic"
                      initialValues={payeeData}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    >
                      <Row
                        style={{
                          padding: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col xs={24} md={11}>
                          <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your first name!",
                              },
                            ]}
                          >
                            <Input disabled={!editMode} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                          <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your last name!",
                              },
                            ]}
                          >
                            <Input disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          padding: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col xs={24} md={11}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Please input a valid email!",
                              },
                            ]}
                          >
                            <Input disabled={!editMode} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                          <Form.Item
                            label="Client Id"
                            name="clientId"
                            rules={[
                              {
                                required: true,
                                message: "Please input a valid client id",
                              },
                            ]}
                          >
                            <Input disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          padding: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col xs={24} md={11}>
                          <Form.Item
                            label="Country"
                            name="country"
                          >
                            <Input disabled={!editMode} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                          <Form.Item
                            label="Date of Birth"
                            name="dateOfBirth"
                          >
                            <Input disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        {editMode && (
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              size={"large"}
                              disabled={isUpdating}  
                              style={{ padding: "12px 40px", height: "auto" }}
                            >
                              Update Info
                            </Button>
                          </Form.Item>
                        )}
                      </Row>
                    </Form>
                  </Col>
                </Row>
                {!editMode && (
                  <Row justify="center">
                    <Button
                      type="primary"
                      size={"large"}
                      style={{ padding: "12px 40px", height: "auto" }}
                      onClick={() => setEditMode(true)}
                    >
                      Edit Info
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PayeeInfo;
