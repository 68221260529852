import { Card, Col, Row } from 'antd'

function Managesubscription() {
  return (
    <>
      <Row justify="center">
        <Col xs={24} md={24} xl={24}>
          <h4 style={{ textAlign: "center", color:"#fff", margin:"0 0 10px" }}>Wishlist</h4>
          <Card>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Managesubscription