import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux for managing auth
import { removeUser } from '../redux/slice/authSlice';

const time = 1000 * 60 * 10;

const useIdleLogout = (timeout = time) => { // Default timeout is 5 minutes (300,000 ms)
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);

  const handleLogout = useCallback(() => {
    dispatch(removeUser()); // Trigger logout action
  }, [dispatch]);

  const resetTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, timeout);
  }, [timeout, handleLogout]);

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

    const handleActivity = () => {
      resetTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeout, resetTimer]);
};

export default useIdleLogout;
