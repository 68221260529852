import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Typography
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../config/api/get";
import { USERS } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

function ChangeEmail() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [user, setUser] = useState({});


    const getUser = useCallback(async () => {
      setLoading(true);
      const users = [
        { id: 1, fullname: "John Doe", bookPrice: "$50", hardcopy: "$60" },
        { id: 2, fullname: "Jane Smith", bookPrice: "$50", hardcopy: "$60" },
        { id: 3, fullname: "Bob Johnson", bookPrice: "$50", hardcopy: "$60" }
      ];
      let _user = users.find((item) => item.id === id);
      setUser(_user);
      setLoading(false);
    }, [id]); 

  useEffect(() => {
    getUser();
  }, [getUser]);




  const handleStatus = async () => {
    try {
      await Get(
        USERS.toggleStatus + "/" + user._id,
        token,
        {}
      );
      const newUser = { ...user };

      newUser.isActive = !user.isActive;
      setModalOpen(false);
      setUser(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const submit = (values) => {
    try {
      setLoading(true);
      let data = {
        email: values.email
      }
      Post('/email/change', data, token)
        .then(async (response) => {
          setLoading(false);
          if (response?.status) {
            navigate('/')
            form.resetFields();
            swal("System Alert", response?.message, "success");
          }
        })
        .catch((err) => {
          setLoading(false);
          let message = err?.response?.data?.message || err?.message;
          console.log(":::;", err);
          if (message) swal("Oops!", message, "error");
        });
    } catch (error) {
      setLoading(false);
      swal("Oops!", error.message, "error");
    }
  }

  return (
    <Layout className="configuration">
      <div className="boxDetails">
        <Row justify="center">
          <Col xs={24} md={24} xl={24}>
            <h4 style={{ textAlign: "center", color: "#fff", margin: "0 0 10px" }}>Profile Information</h4>
            <Card>
              <Row style={{ padding: "10px 20px" }}>
                <Col
                  xs={24}
                  md={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaArrowLeft
                    style={{ fontWeight: "bold", fontSize: "20px" }}
                    onClick={() => navigate(-1)}
                  />
                  &emsp;
                  <h1 className="pageTitle" style={{ margin: 0 }}>
                    Change Email
                  </h1>
                </Col>
              </Row>
              <br />
              <Row style={{ padding: "20px", justifyContent: "center" }}>
                <Col xs={24} md={8}>
                  <Form
                    layout="vertical"
                    name="basic"
                    labelCol={{
                      span: 0,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    form={form}
                    onFinish={submit}
                  >
                    <Form.Item
                      label="New Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your new email!",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter New Email"
                        style={{
                          borderRadius: "5px",
                          fontSize: "14px",
                          padding: "10px 20px",
                        }}
                      />
                    </Form.Item>
                    <br />

                    <Row justify="center">
                      <Form.Item>
                        <Button
                          type="button"
                          size={"large"}
                          style={{ padding: "12px 40px", height: "auto" }}
                          className="mainbtn"
                          htmlType="submit"
                          loading={loading}
                          disabled={loading}
                        >
                          Change Email
                        </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                </Col>
              </Row>


            </Card>
          </Col>
        </Row>



      </div>




      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            className="yes-btn"
          >
            Okay
          </Button>,
        ]}
        cancelButtonProps={false}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        okButtonProps={{}}
      >
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          System Message!
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Email has been changed successfully!
        </Typography.Text>
      </Modal>
    </Layout>
  );
}
export default ChangeEmail;
