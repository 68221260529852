import { Card, Col, Layout, List, Row } from 'antd'
import { CiHeart } from "react-icons/ci"
import { FaUserCircle } from "react-icons/fa"
import { MdOutlineEmail } from "react-icons/md"
import { TbLockPassword } from "react-icons/tb"
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

function Profile() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.userData);

    return (
        <>
            <Layout style={{ minHeight: "100vh" }} className="innerpage">
                <Row justify={"center"}>
                    <Col xs={22} md={22} >
                        <Row justify={"center"} gutter={40}>
                            <Col xs={22} md={6} className='sidebar' >
                                <h4>Hello, {user?.firstName} {user?.lastName}</h4>
                                <Card>
                                    <List
                                        itemLayout="horizontal"
                                    >
                                        <List.Item onClick={() => navigate("/profile")}>
                                            <List.Item.Meta
                                                title={<><FaUserCircle /> &emsp; {"My Profile"}</>}

                                            />
                                        </List.Item>


                                        <List.Item onClick={() => navigate("/profile-details")}>
                                            <List.Item.Meta
                                                title={<><MdOutlineEmail /> &emsp; {"Profile Details"}</>}

                                            />
                                        </List.Item>
                                        <List.Item onClick={() => navigate("change-email")}>
                                            <List.Item.Meta
                                                title={<><MdOutlineEmail /> &emsp; {"Change Email"}</>}

                                            />
                                        </List.Item>

                                        <List.Item onClick={() => navigate("change-password")}>
                                            <List.Item.Meta
                                                title={<><TbLockPassword /> &emsp; {"change-password"}</>}
                                            />
                                        </List.Item>

                                        <List.Item onClick={() => navigate("payee-info")}>
                                            <List.Item.Meta
                                                title={<><CiHeart /> &emsp; {"Payee Info"}</>}
                                            />
                                        </List.Item>


                                        
                                    </List>
                                </Card>
                            </Col>
                            <Col xs={22} md={18} >
                                <Outlet />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout>

        </>

    )
}

export default Profile