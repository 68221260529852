import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ClientLayout from "../../components/layout";
import AboutPage from '../../views/about';
import CheckOut from "../../views/checkout";
import Coins from '../../views/coins';
import Community from '../../views/community';
import ContactUs from '../../views/contactUs';
import ConvertCoins from "../../views/convertCoins";
import ForgetPasswordCode from "../../views/fogetpasswordcode";
import ForgetPassword from "../../views/forgetpassword";
import GiveaWay from '../../views/giveAways';
import GiveawayDetails from "../../views/giveAways/giveawayDetails";
import Home from "../../views/home";
import Login from "../../views/login";
import MyCart from "../../views/myCart";
import PasswordRecovery from "../../views/passwordrecovery";
import PaymentDetails from "../../views/paymentDetails";
import PaymentSuccess from "../../views/paymentSuccess";
import PodCast from '../../views/podcast';
import PrivacyTerms from '../../views/policy';
import ProductDetails from "../../views/productDetails";
import ProtectedRoute from "../../views/protectedRoute";
import RedeemCoins from "../../views/redeem";
import RequestCoins from "../../views/requestCoins";
import Shop from "../../views/shop";
import Signup from "../../views/signup";
import TicketPackages from '../../views/ticket-packages';
import Tickets from "../../views/tickets";
import WinnerList from '../../views/winnerList';



// import Chat from "../../components/oldChatCom";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import AptPayment from "../../views/paymentDetails/aptPay";
import ChangePassword from '../../views/profile/changePassword';
import EditProfile from '../../views/profile/editProfile';
import Managesubscription from '../../views/profile/managesubscription';
import OrderDetail from '../../views/profile/orderDetail';
import OrderHistory from '../../views/profile/orderhistory';
import PayeeInfo from "../../views/profile/payee";
import ChangeEmail from "../../views/profile/changeEmail";
import useIdleLogout from "../../hooks/useIdleLogout";
import Chat from "../../components/chat";
import ConfirmOtp from "../../views/confirm-otp";
import { useDispatch, useSelector } from "react-redux";
import { isTokenExpired } from "../helper";
import Profile from "../../views/profile"; 
import BankDetails from "../../views/bank-details";
import MyOrder from "../../views/my-orders";
import MyOrderDetail from "../../views/my-orders/orderDetails";

function MyRouter() {
  useIdleLogout();
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("userData") && localStorage.getItem("userToken")) {
      dispatch(
        addUser({
          user: JSON.parse(localStorage.getItem("userData")),
          token: localStorage.getItem("userToken"),
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired(token)) {
        dispatch(removeUser());
        window.location.reload()
      }
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval); // Cleanup interval
  }, [dispatch,token]);

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout header={true}>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/shop"
            index
            element={
              <ClientLayout header={true}>
                <Shop />
              </ClientLayout>
            }
          />
          <Route
            path="/profile-details"
            index
            element={
              <ProtectedRoute>
              <ClientLayout header={true}>
                <BankDetails />
              </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-orders"
            index
            element={
              <ProtectedRoute>
              <ClientLayout header={true}>
                <MyOrder />
              </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-orders/order-details/:id"
            index
            element={
              <ProtectedRoute>
              <ClientLayout header={true}>
                <MyOrderDetail />
              </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/convert-to-bank"
            index
            element={
              <ClientLayout header={true}>
                <RedeemCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/redeem-gold-coins"
            index
            element={
              <ClientLayout header={true}>
                <RedeemCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/convert-coins"
            index
            element={
              <ClientLayout header={true}>
                <ConvertCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/request-coins"
            index
            element={
              <ClientLayout header={true}>
                <RequestCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/product-details/:id"
            index
            element={
              <ClientLayout header={true}>
                <ProductDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/payment-success"
            index
            element={
              <ClientLayout header={true}>
                <PaymentSuccess />
              </ClientLayout>
            }
          />
          <Route
            path="/cart"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <MyCart />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <CheckOut />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-details/:orderId"
            index
            // lazy={true}
            element={
              <ClientLayout header={true}>
                <PaymentDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/payment/:orderId"
            index
            // lazy={true}
            element={
              <ClientLayout header={true}>
                <AptPayment />
              </ClientLayout>
            }
          />
          <Route
            path="/login"
            index
            element={
              <ClientLayout header={true}>
                <Login />
              </ClientLayout>
            }
          />
          <Route
            path="/Signup"
            index
            element={
              <ClientLayout header={true}>
                <Signup />
              </ClientLayout>
            }
          />

          <Route
            path="/confirm-otp"
            index
            element={
              <ClientLayout header={true}>
                <ConfirmOtp />
              </ClientLayout>
            }
          />

          <Route
            path="/forgetpassword"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPassword />
              </ClientLayout>
            }
          />
          <Route
            path="/forgetpasswordcode"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPasswordCode />
              </ClientLayout>
            }
          />
          <Route
            path="/passwordrecovery"
            index
            element={
              <ClientLayout header={true}>
                <PasswordRecovery />
              </ClientLayout>
            }
          />

          <Route
            path="/contact-us"
            index
            element={
              <ClientLayout header={true}>
                <ContactUs />
              </ClientLayout>
            }
          />
          <Route
            path="/chat"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Chat />
                </ClientLayout>
              </ProtectedRoute>
            }
          />

          <Route path="/my-buxx-coins" index element={<ClientLayout header={true}><Coins /></ClientLayout>} />
          <Route path="/purchased-tickets" index element={<ClientLayout header={true}><Tickets /></ClientLayout>} />

          <Route
            path="/give-away"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <GiveaWay />
                </ClientLayout>
              </ProtectedRoute>
            } />


          <Route path="/give-away/:id" index element={<ClientLayout header={true}><GiveawayDetails /></ClientLayout>} />
          <Route path="/winner-list" index element={<ClientLayout header={true}><WinnerList /></ClientLayout>} />
          <Route path="/ticket-packages" index element={<ClientLayout header={true}><TicketPackages /></ClientLayout>} />
          <Route path="/podcast" index element={<ClientLayout header={true}><PodCast /></ClientLayout>} />
          <Route
            path="/community"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Community />
                </ClientLayout>
              </ProtectedRoute>
            } />
          <Route path="/about-us" index element={<ClientLayout header={true}><AboutPage /></ClientLayout>} />
          <Route path="/privacy-terms" index element={<ClientLayout header={true}><PrivacyTerms /></ClientLayout>} />
          <Route path="/contact-us" index element={<ClientLayout header={true}><ContactUs /></ClientLayout>} />
          <Route path="/profile" element={<ClientLayout header={true}><Profile /></ClientLayout>} >
            <Route index element={<EditProfile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="change-email" element={<ChangeEmail />} />
            <Route path="wishlist" element={<Managesubscription />} />
            <Route path="order-history" element={<OrderHistory />} />
            <Route path="order-detail/:id" element={<OrderDetail />} />
            <Route path="payee-info" element={<PayeeInfo />} />
          </Route>



        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;
