import { Col, Image, List, Row } from "antd";
import { BsPlayCircle } from "react-icons/bs";
import AudioPlayerComp from "../../components/audioPlayer";
import { ImageUrl } from "../../config/helper";

function PodCast() {
    const data = [
        {
          time:"08:00",
          title: 'Start with Why',
          
        },
        {
          time:"02:00",
          title: 'Start with Why',
        },
        {
          time:"03:00",
          title: 'Start with Why',
        },
        {
          time:"05:00",
          title: 'Start with Why',
        },
      ];
  return (
    <div>
    <div className="position-relative">
      <Image
        preview={false}
        src={ImageUrl("podcast.png")}
        className="bg-vid"
      />
      
      
    </div>
    <div className="banner">
        
        <Row justify={"center"}>
          <Col xs={22} md={20}>
            <h1>
            How to avoid making<br/>bad choices
              
            </h1>
            <p>How to make the perfect playlist in five minutes</p>
          </Col>
        </Row>
      </div>
    <div className="innerpage pt-25">
      <Row justify={"center"}>
      <Col xs={22} md={18}>
         <AudioPlayerComp/>
        </Col>
        <Col xs={22} md={20}>
          <h5 className="mt-50 text-left">About this podcast</h5>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially . It was popularised in the with the release of Letraset sheets. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially . It was popularised in the with the release of Letraset sheets.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan.</p>
            
        </Col>
<Row justify="center" className="mt-50">
<Col xs={22} md={12} lg={13}><Image
        preview={false}
        src={ImageUrl("podcastbig.png")}
      /></Col>
        <Col xs={20} md={12} lg={11}>
            <Row justify={"center"}>
                <Col xs={24} md={20} lg={22}>
                <h5 className="text-left mt-15 mb-15">Listen to the Specific Part</h5>
                            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                    avatar={<div ><BsPlayCircle /></div>}
                    description={item.time}
                    title={<div>{item.title}</div>}
                    
                    />
                </List.Item>
                )}
            />
                </Col>
            </Row>
            
            
        </Col>
</Row>
       
        
      </Row>
    </div>
    
  </div>
  )
}

export default PodCast