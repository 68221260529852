import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  userToken: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      // console.log("payload",payload)
      state.userData = payload.user
      state.userToken = payload.token
      localStorage.setItem('userData', JSON.stringify(payload.user));
      localStorage.setItem('userToken', payload.token);
    },
    updateUserData: (state, { payload }) => {
      state.userData = payload.user
      localStorage.setItem('userData', JSON.stringify(payload.user));
    },
    removeUser: (state) => {
      state.userData = {};
      state.userToken = null;
      localStorage.clear();
    },
    addData: (state, { payload }) => {
      // console.log(payload)
      state[payload.name] = payload.value
      localStorage.setItem(payload.name, JSON.stringify(payload.value));
    },

  },
})

// Action creators are generated for each case reducer function
export const { addUser, removeUser, updateUserData, addData, setKeyword } = userSlice.actions

export default userSlice.reducer