import { useEffect } from 'react';
import ScrollTop from '../ScrollTop/ScrollTop'
import Footer from './Footer'
import Header from './header'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
function ClientLayout({header,children,footer=true})  {

  const navigate = useNavigate()
  const {pathname} = useLocation()
  
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.status !== "ACTIVE" && pathname !== "/chat" && userData) {
      navigate('/');
    }
  }, [navigate,pathname]);

  return (
    <>
      <ScrollTop />
    {header && <Header/>}
    {children}
   {footer && <Footer/>}
    </>
  )
}

export default ClientLayout