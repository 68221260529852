"use client";
// import Spinner from "@/components/Spinner";
import { ImageUrl as imageConstantUrl } from "../../config/helper";

import { useGetOrderByIdQuery } from "../../api/orderApi";

import { useGetClientIdQuery } from "../../api/cart";

import moment from "moment";
import { useEffect } from "react";
import { FaCheck } from "react-icons/fa6";
import { MdIncompleteCircle } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

export default function OrderDetails() {

    const navigate = useNavigate()
    const { id: orderId } = useParams();
    const { data, isFetching, refetch } = useGetOrderByIdQuery(orderId);

    // Paypal Keys
    const { data: clientId } = useGetClientIdQuery({});
    // console.log("🚀 ~ OrderDetails ~ paypalKey:", clientId)

    // const { data: StripeKey } = useGetPublishableKeyQuery({});
    // console.log("🚀 ~ OrderDetails ~ StripeKey:", StripeKey)



    useEffect(() => {
        if (clientId) {
            localStorage.setItem("clientId", clientId)
        }
    }, [clientId])


    useEffect(() => {
        refetch();
    }, [orderId, refetch]);



    if (isFetching) {
        return (
            <div className="flex justify-center items-center h-screen text-white">
                {/* <Spinner /> */}
                Loading...
            </div>
        );
    }

    return (
        <section className="py-24 relative text-white">
            <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto text-white">
                <h2 className="font-manrope font-bold text-3xl sm:text-4xl   leading-10  mb-11">
                    Order Details
                </h2>
                {/* <h6 className="font-medium text-xl leading-8 text-white mb-3">
            Hello, {orderData.customerName}
            </h6> */}

                {data?.paymentStatus === "paid" ? (
                    <div className="font-normal text-lg leading-8 text-white mb-11 flex items-center gap-2">
                        <div className="w-10 h-10 bg-green-500 flex justify-center items-center text-white font-bold rounded-full">
                            <FaCheck />
                        </div>
                        Your order has been completed.
                    </div>
                ) : (
                    <div className="font-normal text-lg leading-8 text-white mb-11 flex items-center gap-2">
                        <div className="w-10 h-10 bg-red-500 flex justify-center items-center text-white font-bold rounded-full">
                            <MdIncompleteCircle />
                        </div>
                        Your order has not completed Yet.
                    </div>
                )}

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-8 py-6 border-y border-gray-100 mb-6">
                    {/* delivery Date */}
                    {/* <div className="box group">
                <p className="font-normal text-base leading-7 text-white mb-3 transition-all duration-500 group-hover:text-gray-700">
                Delivery Date
                </p>
                <h6 className="font-semibold font-manrope text-2xl leading-9 text-white">
                {orderData.deliveryDate}
                </h6>
            </div> */}
                    <div className="box group">
                        <p className="font-normal text-base leading-7 text-white mb-3 transition-all duration-500 group-hover:text-gray-700">
                            Created At
                        </p>
                        <h6 className="font-semibold font-manrope text-2xl leading-9 text-white">
                            {moment(data?.createdAt).format("MMMM Do YYYY, h:mm a")}
                        </h6>
                    </div>

                    <div className="box group">
                        <p className="font-normal text-base leading-7 text-white mb-3 transition-all duration-500 group-hover:text-gray-700">
                            Order
                        </p>
                        <h6 className="font-semibold font-manrope text-xl leading-9 text-white">
                            {data?._id}
                        </h6>
                    </div>
                    <div className="box group md:ml-5">
                        <p className="font-normal text-base leading-7 text-white mb-3 transition-all duration-500 group-hover:text-gray-700">
                            Payment Method
                        </p>
                        <h6 className="font-semibold font-manrope text-xl leading-9 text-white flex justify-start items-center gap-2">
                            {data?.paymentMethod ? (
                                <img
                                    width={40}
                                    height={40}
                                    src={
                                        data?.paymentMethod === "redCoins"
                                            ? "red-coin.png"
                                            : data?.paymentMethod === "paypal"
                                                ? "paypal.webp"
                                                : data?.paymentMethod === "stripe"
                                                    ? "stripe.png"
                                                    : null
                                    }
                                    alt={"paymentMethod"}
                                    className=" object-cover"
                                />
                            ) : (
                                "-"
                            )}
                            {data?.paymentMethod === "redCoins"
                                ? "Red Coins"
                                : data?.paymentMethod === "paypal"
                                    ? "Paypal"
                                    : data?.paymentMethod === "Stripe"
                                        ? "Stripe"
                                        : ""

                            }
                        </h6>
                    </div>
                    <div className="box group">
                        <p className="font-normal text-base leading-7 text-white mb-3 transition-all duration-500 group-hover:text-gray-700">
                            Address
                        </p>
                        <h6 className="font-semibold font-manrope text-2xl leading-9 text-white">
                            {/* {data?.shippingDetails?.address} {data?.shippingDetails?.state} */}
                            {data?.shippingDetails
                                ? `${data.shippingDetails.address} ${data.shippingDetails.state}`
                                : "No Address Available"}
                        </h6>
                    </div>
                </div>

                {/* Products */}
                {data?.products?.map((item) => (
                    <div
                        className="grid grid-cols-7 w-full py-6 border-b border-gray-100"
                        key={item?._id}
                    >
                        <div className="col-span-7 min-[500px]:col-span-2 md:col-span-1">
                            <img
                                width={200}
                                height={200}
                                src={
                                    item?.image
                                        ? `${imageConstantUrl}${item?.image}`
                                        : "https://static.vecteezy.com/system/resources/thumbnails/019/514/650/small/letter-s-logo-design-for-business-and-company-identity-with-luxury-concept-free-vector.jpg"
                                }
                                alt={"product"}
                                className="w-full rounded-xl object-cover"
                            />
                        </div>
                        <div className="col-span-7 min-[500px]:col-span-5 md:col-span-6 min-[500px]:pl-5 max-sm:mt-5 flex flex-col justify-center">
                            <div className="flex flex-col min-[500px]:flex-row min-[500px]:items-center justify-between">
                                <div className="">
                                    <h5 className="font-manrope font-semibold text-2xl leading-9 text-white mb-6">
                                        {item?.product?.title ? item?.product?.title : "-"}
                                    </h5>
                                    <p className="font-normal text-xl leading-8 text-white">
                                        Quantity:{" "}
                                        <span className="text-white font-semibold">
                                            {item?.quantity}
                                        </span>
                                    </p>
                                </div>
                                <h5 className="font-manrope font-semibold text-3xl leading-10 text-white sm:text-right mt-3">
                                    ${item?.price?.toFixed(2)}
                                </h5>
                            </div>
                        </div>
                    </div>
                ))}

                <div className="flex items-center justify-center sm:justify-end w-full my-6">
                    <div className="w-full">
                        <div className="flex items-center justify-between mb-6">
                            <p className="font-normal text-xl leading-8 text-white">
                                Subtotal
                            </p>
                            <p className="font-semibold text-xl leading-8 text-white">
                                ${data?.totalPrice?.toFixed(2)}
                            </p>
                        </div>
                        {/* 
                <div className="flex items-center justify-between mb-6">
                <p className="font-normal text-xl leading-8 text-white">
                    Shipping Charge
                </p>
                <p className="font-semibold text-xl leading-8 text-gray-900">
                    ${orderData.shippingCharge.toFixed(2)}
                </p>
                </div> */}

                        {/* 
                <div className="flex items-center justify-between mb-6">
                <p className="font-normal text-xl leading-8 text-white">
                    Taxes
                </p>
                <p className="font-semibold text-xl leading-8 text-gray-900">
                    ${orderData.taxes.toFixed(2)}
                </p>
                </div> */}
                        <div className="flex items-center justify-between mb-6">
                            <p className="font-normal text-xl leading-8 text-white">
                                Discount
                            </p>
                            <p className="font-semibold text-xl leading-8 text-white">
                                $0
                            </p>
                        </div>
                        <div className="flex items-center justify-between py-6 border-y border-gray-100">
                            <p className="font-manrope font-semibold text-2xl leading-9 text-white">
                                Total
                            </p>
                            <p className="font-manrope font-bold text-2xl leading-9 text-indigo-600">
                                ${data?.totalPrice?.toFixed(2)}
                            </p>
                        </div>
                    </div>
                </div>
                {/* /dashboard/check-out */}
                <div className="data flex flex-col-reverse md:flex-row gap-8 md:gap-0 md:justify-between items-center">
                    <div>
                        <p className="font-normal text-lg leading-8 text-white mb-11">
                            We&apos;ll be sending a shipping confirmation email when the items ship
                            successfully.
                        </p>
                        <h6 className="font-manrope font-bold text-2xl leading-9 text-white mb-3">
                            Thank you for shopping with us!
                        </h6>
                        <p className="font-medium text-xl leading-8 text-indigo-600">
                            Team Real Money
                        </p>
                    </div>
                    {data?.paymentStatus === "unpaid" && (
                        <div

                            onClick={() => {
                                navigate(`/payment-details/${orderId}`)
                            }}

                            className="flex items-center justify-center rounded-md border border-transparent bg-green-500 px-6 py-3 text-base  text-white shadow-xs hover:bg-green-600 font-semibold"
                        >
                            proceed to payment
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};



