import { Button, Col, Image, Row } from "antd";
import { ImageUrl } from "../../config/helper";

function TicketPackages() {

    const packageDetails=[
        {
          key: 1,
          image: "ticket-1.png",
          tickets:"20",
          days: "03",
          amount:"10.00"
        },
        {
            key: 2,
            image: "ticket-2.png",
            tickets:"20",
            days: "03",
            amount:"10.00"
          },
          {
            key: 3,
            image: "ticket-3.png",
            tickets:"20",
            days: "03",
            amount:"10.00"
          },
          {
            key: 4,
            image: "ticket-4.png",
            tickets:"20",
            days: "03",
            amount:"10.00"
          },
    
        

      ]


  return (
    <div>
    <div className="position-relative">
      <Image
        preview={false}
        src={ImageUrl("dragon-bg.png")}
        className="bg-vid"
      />
      <Image
        preview={false}
        src={ImageUrl("footer-dragon.png")}  className="side-dragon"
      />
      
    </div>
    <div className="innerpage position-relative z-index-99">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5 className="mt-50">TICKET PACKAGES</h5>
        </Col>
        <Col xs={22} md={20}>
          <Row gutter={40} justify={"center"}>
          {packageDetails.length > 0 &&
                packageDetails.map((item) => {
                  return (
                    <Col xs={24} md={18} lg={12}>
            
                <TicketDetailsPackage {...item}/>
            </Col>
                  );
                })}
            
          </Row>
        </Col>
      </Row>
    </div>
    
  </div>
  );
}

export default TicketPackages

export function TicketDetailsPackage({image, tickets, days, amount}) {
    return (
      <div className="ticket-packages">
        <Row gutter={20}>
            <Col xs={24} sm={10} md={8} lg={8}>
                <Image preview={false} src={ImageUrl(image)} />
            </Col>
            <Col xs={24} sm={14} md={16} lg={16}>
               <div className="ribbon-both">{tickets} Tickets for {days} Days</div>
               <h3><sup>$</sup>{amount}</h3>
               <Button className="mainbtn">BUY TICKET</Button>
            </Col>
        </Row>
      </div>
    );
}