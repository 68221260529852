import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Button, Col, Form, Image, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientIdQuery, useGetOrderByIdQuery } from "../../api/cart";
import { ImageUrl } from "../../config/helper";
import PaypalCard, { MyPayButton } from "../paypal";
import VivamoPayment from "../vivamo/payment";
import { useGetMeQuery } from "../../api/profile";

function PaymentDetails() {
  const { orderId } = useParams();

  const { data: orderDataApi } = useGetOrderByIdQuery(orderId);
  const orderData = orderDataApi?.order;
  const totalPrice = Number(orderData?.totalPrice).toFixed(2);

  const { NODE_ENV } = process.env;
  const { hostname } = window.location;

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentType] = useState("bank");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { data: clientId } = useGetClientIdQuery();
  const [myClientId, setMyClientId] = useState(clientId);
  const [showButton, setShowButton] = useState(true);

  const { data: userProfile } = useGetMeQuery()

  useEffect(() => {
    if (userProfile?.vivamoId) {
      setShowButton(false)
    }
  }, [userProfile])

  useEffect(() => {
    let id = localStorage.getItem("clientId");
    if (clientId) {
      id = clientId;
      localStorage.setItem("clientId", clientId);
    }
    setMyClientId(id);
  }, [clientId]);

  // const onChange = (e) => {
  //   setPaymentType(e.target.value);
  // };

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>
            <IoIosArrowDropleftCircle onClick={() => navigate("/checkout")} />
            PAYMENT DETAILS
          </h5>

          <div className="about-section product-details">
            <Row gutter={20}>


              <Col xs={24} md={14} lg={12}>

                {showButton ?
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ minWidth: 119 }}
                      className="p-6"
                      onClick={() => {
                        navigate("/profile-details")
                      }}
                    >
                      Fill The Bank Details

                    </Button>
                  </Form.Item>

                  :
                  <>
                    <div
                      style={{
                        backgroundColor: "",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="">
                        <span className="card-title">Amount Payable</span>
                        <span className="card-price">$ {totalPrice}</span>
                      </div>
                    </div>

                    <Image
                      preview={false}
                      src={ImageUrl("master-cards.png")}
                      className="mt-15 mb-15 master-card"
                    />
                    <br />
                    <br />
                    {paymentType === "bank" && <VivamoPayment orderId={orderId} />}
                    {paymentType === "paypal" && (
                      <div className="checkout-form">
                        {myClientId ? (
                          <PayPalScriptProvider
                            options={{
                              "client-id": myClientId,
                              environment:
                                NODE_ENV === "production" &&
                                  hostname.includes("realmoneydragon.io")
                                  ? "production"
                                  : "sandbox",
                            }}
                          >
                            {totalPrice > 0 ? (
                              <PaypalCard
                                localOrderData={orderData}
                                totalPrice={totalPrice}
                                clientId={myClientId}
                              />
                            ) : null}
                          </PayPalScriptProvider>
                        ) : (
                          <MyPayButton />
                        )}
                      </div>
                    )}
                  </>
                }






              </Col>



              <Col xs={24} md={10} lg={12}>
                <Image
                  preview={false}
                  src={ImageUrl("payment-card.png")}
                  className="mb-15"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Image
          preview={false}
          src={ImageUrl("tick.png")}
          className="mt-15 mb-15 tickimage"
        />
        <h4>PAYMENT SUCCESS</h4>
        <p>Your payment has been made successfully!</p>

        <Col xs={24} className="flex justify-center items-center mt-15">
          <Button className="mainbtn" onClick={() => navigate("/")}>
            CONTINUE
          </Button>
        </Col>
      </Modal>
    </div>
  );
}

export default PaymentDetails;
