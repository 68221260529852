import { Button, Col, Form, Image, Input, Modal, Row } from "antd";
import { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useCreateTransactioinMutation } from "../../api/aptPay";
import { useGetOrderByIdQuery } from "../../api/cart";
import { ImageUrl } from "../../config/helper";

const { NODE_ENV } = process.env;

function AptPayment() {
  const { orderId } = useParams()
  const [form] = Form.useForm();


  const { data: orderDataApi } = useGetOrderByIdQuery(orderId)
  const orderData = orderDataApi?.order
  const totalPrice = Number(orderData?.totalPrice).toFixed(2)

  // const [createTransaction, { data: txnData, isLoading }] = useCreateTransactioinMutation();
  const [createTransaction, { isLoading }] = useCreateTransactioinMutation();

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onFiinish = (values) => {
    try {
      if (isLoading) return;

      let data = {
        accountNumber: values.accountNumber,
        routingNumber: values.routingNumber,
        orderId
      }

      createTransaction(data).then(({ error, data }) => {
        if (data?.success) {
          showModal();
        }
        const { errors, message: errMsg } = error?.data?.error
        if (errors) {
          swal(errMsg, JSON.stringify(errors), "error");
        }
      }).catch((error) => {
        console.log("Error submitting form", error);
      });
    } catch (error) {
      console.log("Error submitting form", error);
    }
  }

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>
            <IoIosArrowDropleftCircle onClick={() => navigate("/checkout")} />
            PAYMENT DETAILS
          </h5>

          <div className="about-section product-details">
            <Row gutter={20} >
              <Col xs={24} md={14} lg={12}>

                <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                  <div className="" >

                    <span className="card-title">Amount Payable</span>
                    <span className="card-price">$ {totalPrice}</span>
                  </div>
                </div>

                <div className="checkout-form">
                  <Form layout="vertical" name="basic" form={form} onFinish={onFiinish}>
                    <Row gutter={20}>
                      <Col xs={24}>
                        <Form.Item
                          label="Enter Account Number"
                          name="accountNumber"
                          initialValue={NODE_ENV === 'development' ? '65516645' : ''}
                          rules={[
                            {
                              type: "text",
                              message: "Please Enter Account Number",
                            },
                            {
                              required: true,
                              message: "Please Enter Account Number!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Account Number"
                            prefix={<FaRegUser />}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          label="Enter Routing Number"
                          name="routingNumber"
                          initialValue={NODE_ENV === 'development' ? '123456789' : ''}
                          rules={[
                            {
                              type: "text",
                              message: "Please Enter Routing Number",
                              // warningOnly: true,
                            },
                            {
                              required: true,
                              message: "Please Enter Routing Number!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Routing Number"
                            prefix={<FaRegUser />}
                          />
                        </Form.Item>
                      </Col>

                    </Row>

                    <Col xs={24} className="mt-15"><Button className="mainbtn" htmlType="submit">PAY NOW</Button></Col>

                  </Form>

                </div>
              </Col>
              <Col xs={24} md={10} lg={12}>
                <Image
                  preview={false}
                  src={ImageUrl("payment-card.png")}
                  className="mb-15"
                />
              </Col>

            </Row>
          </div>
        </Col>
      </Row>

      <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Image
          preview={false}
          src={ImageUrl("tick.png")}
          className="mt-15 mb-15 tickimage"
        />
        <h4>PAYMENT SUCCESS</h4>
        <p>Your payment has been made successfully! Waiting for confirmation.</p>

        <Col xs={24} className="flex justify-center items-center mt-15"><Button className="mainbtn" onClick={() => navigate("/")}>CONTINUE</Button></Col>
      </Modal>
    </div>
  )
}

export default AptPayment