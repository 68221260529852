import { Col, Row } from "antd";
import swal from "sweetalert";
import { useBuyTicketPackageMutation, useGetAllTicketPackagesQuery } from '../../api/ticket';
import CoinsCard from '../../components/coinsCard';

function Coins() {
  let { data: ticketPackages } = useGetAllTicketPackagesQuery();
  ticketPackages = ticketPackages?.data || [];


  // const [buyTicketPackage, { data: buyTicketPackageData, isLoading: buyTicketPackageLoading, isError, error, isSuccess }] = useBuyTicketPackageMutation();
  const [buyTicketPackage] = useBuyTicketPackageMutation();

  const handleBuyPackage = (id) => {
    buyTicketPackage(id).then((res) => {
      if (res.data?.status) {
        swal("Success", res?.data?.message, "success");
      } else {
        swal("Error", res.data?.message || "An error occured", "error");
      }
    }
    );
  }

  return (
    <div className='innerpage'>
      <Row justify={"center"}>
        <Col xs={22} md={22} lg={20}>
          <h5>My Buxx Coins</h5>
          <h6>Absolutely. Positively. Perfect</h6>
          <Row gutter={20} justify={"center"}>

            {ticketPackages.length > 0 &&
              ticketPackages.map((val, ind) => {
                return (
                  <Col xs={22} md={12} lg={8}>
                    <CoinsCard {...val} index={ind}
                      handleBuyPackage={() => { handleBuyPackage(val._id) }}
                    />
                  </Col>
                );
              })}


          </Row>


        </Col>
      </Row>
    </div>
  )
}

export default Coins