import { Collapse } from 'antd';

function Faqs() {
    const { Panel } = Collapse;

const text = `
To reset your password, click on the "Forgot Password" link on the login page. You will receive an email with instructions on how to reset it.
`;
  return (
    <div>
        <Collapse accordion>
    <Panel header="1- What is the maximum number of users that can access my account?" key="1">
      <p>{text}</p>
    </Panel>
    <Panel header="2- Can I change my username after creating my account?" key="2">
      <p>{text}</p>
    </Panel>
    <Panel header="3- How do I reset my password if I forget it?" key="3">
      <p>{text}</p>
    </Panel>
    <Panel header="4- Is there a mobile app available for this service?" key="4">
      <p>{text}</p>
    </Panel>
    <Panel header="5- What payment methods are accepted for subscription fees?" key="5">
      <p>{text}</p>
    </Panel>
    <Panel header="6- Can I cancel my subscription at any time?" key="6">
      <p>{text}</p>
    </Panel>
  </Collapse>
    </div>
  )
}

export default Faqs