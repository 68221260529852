import { useEffect, useState } from "react";

const Timer = (targetDate) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24))
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
            const minutes = Math.floor((difference / 1000 / 60) % 60)
            const seconds = Math.floor((difference / 1000) % 60)
            timeLeft = {
                // days,
                hours: hours + (days * 24),
                minutes,
                seconds
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = {days: "00", hours: "00", minutes: "00", seconds: "00"};

    Object.keys(timeLeft).forEach(interval => {
        timerComponents[interval] = timeLeft[interval].toString().length !== 1 ? timeLeft[interval] : "0" + timeLeft[interval]
    });

    return timerComponents
};

export default Timer;