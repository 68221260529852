import AliceCarousel from "react-alice-carousel";
import CardFrame from "../card";

const responsive = {
  380: { items: 1 },
  568: { items: 1 },
  1024: { items: 2 },
  1200: { items: 3 },
  1400: { items: 3 },
  1500: { items: 4 },
};

function CarouselSlider({products}) {

  const items =
    Array.isArray(products) &&  products.length > 0 &&
    products?.map((item, index) => {
      return (
        <div className="item" key={index}>
          <CardFrame item={item} products={products}/>
        </div>
      );
    });

  return (
    <div className="categeorySlider">
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls
        disableDotsControls
        controlsStrategy="alternate"
        paddingLeft={0}
        paddingRight={0}
        infinite
        autoPlay
      />
    </div>
  );
}

export default CarouselSlider;
