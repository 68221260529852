import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Typography
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Post } from "../../config/api";
import { Get } from "../../config/api/get";
import { AUTH, USERS } from "../../config/constants/api";

function ChangePassword() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [user, setUser] = useState({});

  const getUser = useCallback(async () => {
    setLoading(true);
    const users = [
      { id: 1, fullname: "John Doe", bookPrice: "$50", hardcopy: "$60" },
      { id: 2, fullname: "Jane Smith", bookPrice: "$50", hardcopy: "$60" },
      { id: 3, fullname: "Bob Johnson", bookPrice: "$50", hardcopy: "$60" }
    ];
    let _user = users.find((item) => item.id === id);
    setUser(_user);
    setLoading(false);
  }, [id]); 


  useEffect(() => {
    getUser();
  }, [getUser]);


  const handleStatus = async () => {
    try {
     await Get(
        USERS.toggleStatus + "/" + user._id,
        token,
        {}
      );
      const newUser = { ...user };

      newUser.isActive = !user.isActive;
      setModalOpen(false);
      setUser(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = async (values) => {

    if (values.newPassword !== values.confirmPassword) {
      swal("Oops!", "New password and confirm password do not match!", "error");
      return;
    }
    setLoading(true);
    
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    try {
      const response = await Post(AUTH.changePassword, data, token);
      if (response?.status) {
        swal("Success!", "Password Updated Successfully", "success");
        navigate(-1);
      } else {
        swal("Oops!", "Failed to change password.", "error");
      }
    } catch (error) {
      swal("Oops!", error.message || "Error occurred!", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails">
      <Row justify="center">
        <Col xs={24} md={24} xl={24}>
          <h4 style={{ textAlign: "center", color:"#fff", margin:"0 0 10px" }}>Profile Information</h4>
          <Card>
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Change Password
            </h1>
          </Col>
        </Row>
        <br />
        <Row style={{ padding: "20px" , justifyContent:"center" }}>
        <Col xs={24} md={8}>
        <Form
              layout="vertical"
              name="changePassword"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Old Password!",
                  },
                  {
                    type: "string",
                    min: 8,
                    message: "Password must be at least 8 characters!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter Old Password"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your New Password!",
                  },
                  {
                    type: "string",
                    min: 8,
                    message: "Password must be at least 8 characters!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter New Password"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirm Password!",
                  },
                  {
                    type: "string",
                    min: 8,
                    message: "Password must be at least 8 characters!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter Confirm Password"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>
              <br />

              <Row justify="center">
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    style={{ padding: "12px 40px", height: "auto" }}
                    className="mainButton"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Row>
            </Form>
              </Col>
        </Row>


        </Card>
        </Col>
      </Row>
      

        
      </div>
        



      <Modal
          open={modalOpen}
          onOk={() => handleStatus()}
          onCancel={() => setModalOpen(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={loading}
              className="yes-btn"
            >
              Okay
            </Button>,
          ]}
          cancelButtonProps={false}
          okText="Yes"
          className="StyledModal"
          style={{
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
          okButtonProps={{}}
        >
          <Image
            src="../images/done.png"
            preview={false}
            width={74}
            height={74}
          />
          <Typography.Title level={4} style={{ fontSize: "25px" }}>
            System Message!
          </Typography.Title>
          <Typography.Text style={{ fontSize: 16 }}>
            Book Has Been Added Successfully!
          </Typography.Text>
        </Modal>
    </Layout>
  );
}
export default ChangePassword;
