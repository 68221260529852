import { Button, Flex, Image } from "antd";
import { ImageUrl } from "../../config/helper";

function CoinsCard({ name, price, tickets, index, handleBuyPackage }) {
  return (
    <div className='coins-card'>
      <div className='coins-card-top'>
        <Image preview={false} src={ImageUrl(`coins-${(index % 3) + 1}.png`)} />
        <h4>{name}</h4>
        <Flex justify='center' gap={15}>
          <h3>
            {/* <sup>$</sup> */}
            {price}
          </h3>
          <Image
            preview={false}
            src={ImageUrl("gold-coin.png")}
            height={30}
            width={30}
            alt="red"
          />
        </Flex>
      </div>
      <div className='coins-card-bottom'>
        <h5><Image preview={false} src={ImageUrl("coins.png")} />{tickets} Tickets</h5>
        <div className='flex justify-center items-center mt-15'>
          <Button className='mainbtn flex' onClick={handleBuyPackage}>SELECT PACKAGE</Button>
        </div>
      </div>
    </div>
  )
}

export default CoinsCard