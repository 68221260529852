import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
} from "antd";
import { CiMobile1 } from "react-icons/ci";
import { FaEnvelope, FaRegUser } from "react-icons/fa";
import swal from "sweetalert";
import { useGetCustomerQuery, useUpdateBankDetailsMutation } from "../../api/vivamo";
import { Background } from "../home";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

function ProfileDetails() {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState("+1");
    const { data: customerData, isLoading: customerDataLoading } = useGetCustomerQuery();
    const [updateBankDetails, { isLoading: isBankDetailsUpdating }] = useUpdateBankDetailsMutation();
    const navigate = useNavigate();

    useEffect(() => {
        form.setFieldsValue({...customerData});
      }, [form, customerData]);

    const validatePhoneNumber = (_, value) => {
        const regex = /^\+1[2-9]\d{9}$/; // Ensures +1 followed by a valid 10-digit number
        if (!value || regex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error("Invalid phone number."));
    };

    const handlePhoneChange = (e) => {
        let value = e.target.value;

        // Ensure "+1" is always present at the start
        if (!value.startsWith("+1")) {
            value = "+1" + value.replace(/\D/g, ""); // Remove non-numeric characters
        } else {
            value = "+1" + value.substring(2).replace(/\D/g, ""); // Remove non-numeric characters except +1
        }

        // Limit to +1XXXXXXXXXX (12 characters including +1)
        if (value.length > 12) {
            value = value.slice(0, 12);
        }

        setPhone(value);
        form.setFieldsValue({ phone: value });
    };

    const onFinish = (values) => {
        let obj = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: phone,
        };
        updateBankDetails(obj).then((res) => {
            if (res?.data?.status) {
                navigate(-1)
                swal("Success", res?.data?.message, "success");
            } else {
                swal("Error", res?.error?.data?.status[0]?.msg || "An error occured", "error");
            }
        }).catch((err) => {
            swal("Error", err?.data?.message || "An error occured", "error");
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <Background />
            <Layout className="banner">
                <Row className="flex justify-center items-center">
                    <Col xs={22} md={20} lg={16}>
                        <Card>
                            <div className="authFormBox checkout-form">
                                <Row style={{ width: "100%", justifyContent: "center" }}>
                                    <Col xs={20} md={20} className="formWrap">
                                        <h3>ACCOUNT DETAILS</h3>
                                        <p>Fill out the form below to Update Details</p>
                                        <br />
                                        <Form
                                            layout="vertical"
                                            name="basic"
                                            className="loginForm"
                                            labelCol={{
                                                span: 0,
                                            }}
                                            wrapperCol={{
                                                span: 24,
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            form={form}
                                        // autoComplete="off"
                                        >
                                            <Row gutter={20}>



                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        label="Enter First Name"
                                                        name="firstName"
                                                        rules={[
                                                            {
                                                                type: "text",
                                                                message: "Please Enter First Name",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter First Name!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter First Name"
                                                            prefix={<FaRegUser />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Enter Last Name"
                                                        name="lastName"
                                                        rules={[
                                                            {
                                                                type: "text",
                                                                message: "Please Enter Last Name",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Last Name!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Last Name"
                                                            prefix={<FaRegUser />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>

                                                    <Form.Item
                                                        label="Enter Email Address"
                                                        name="email"
                                                        rules={[
                                                            {
                                                                type: "email",
                                                                message: "Please Enter Email Address",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Email Address!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Email Address"
                                                            prefix={<FaEnvelope />}
                                                        />
                                                    </Form.Item>
                                                </Col>


                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Phone Number"
                                                        name="phone"
                                                        rules={[
                                                            { required: true, message: "Please enter your phone number!" },
                                                            { validator: validatePhoneNumber },
                                                        ]}
                                                    >
                                                        <Input size="large" value={phone} onChange={handlePhoneChange} maxLength={12} prefix={<CiMobile1 />} />
                                                    </Form.Item>
                                                </Col>

                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Street"
                                                        name="street"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Valid street address",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Valid street address",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter street"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Street 2"
                                                        name="street2"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Valid street 2 address",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Valid street 2 address",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter street 2"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="City"
                                                        name="city"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter City",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter City",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter City"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Zip"
                                                        name="zip"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Zip",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Zip",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Zip"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Country"
                                                        name="country"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Country",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Country",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Country"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Date Of Birth"
                                                        name="dateOfBirth"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Date Of Birth",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Date Of Birth",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Date Of Birth"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Occupation"
                                                        name="occupation"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Occupation",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Occupation",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Occupation"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Phone"
                                                        name="phone"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Phone",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Phone",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Phone"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="National Identity Number"
                                                        name="nationalIdentityNumber"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter National Identity Number",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter National Identity Number",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter National Identity Number"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col> */}

                                                {/* Non Tax Resident */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Non Tax Resident"
                                                        name="nonTaxResident"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select Non Tax Resident status",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="h-12 rounded-none"
                                                            placeholder="Non Tax Resident"
                                                            getPopupContainer={(trigger) => trigger.parentElement}

                                                        >
                                                            <Select.Option value={true}>True</Select.Option>
                                                            <Select.Option value={false}>False</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}

                                                {/* Jurisdiction of  Tax Residence */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Jurisdiction Of Tax Residence"
                                                        name="jurisdictionOfTaxResidence"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select Jurisdiction Of Tax Residence",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="h-12 rounded-none"
                                                            placeholder="Jurisdiction Of Tax Residence"
                                                            getPopupContainer={(trigger) => trigger.parentElement} // Ensures proper dropdown positioning
                                                        >
                                                            <Select.Option value={true}>True</Select.Option>
                                                            <Select.Option value={false}>False</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}

                                                {/* Tax Identification Number */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Tax Identification Number"
                                                        name="taxIdentificationNumber"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Tax Identification Number",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Tax Identification Number",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Tax Identification Number"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col> */}

                                                {/* NO Tax Id Reason */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="No Tax Id Reason"
                                                        name="noTaxIdReason"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select No Tax Id Reason",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="h-12 rounded-none"
                                                            placeholder="No Tax Id Reason"
                                                            getPopupContainer={(trigger) => trigger.parentElement} // Ensures proper dropdown positioning
                                                        >
                                                            <Select.Option value={true}>True</Select.Option>
                                                            <Select.Option value={false}>False</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}


                                                {/* Social Insurance Number */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Social Insurance Number"
                                                        name="socialInsuranceNumber"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter your Social Insurance Number",
                                                            },
                                                            {
                                                                type: "number",
                                                                message: "Only numbers are allowed",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            className="w-full h-12 rounded-none" // Ensures it spans full width
                                                            placeholder="Enter Social Insurance Number"
                                                            controls={false} // Removes increment/decrement buttons
                                                            min={0} // Ensures no negative numbers
                                                            style={{ width: "100%" }} // Expands width properly inside Form.Item
                                                        />
                                                    </Form.Item>
                                                </Col> */}


                                                {/* Privacy Policy Accepted */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Privacy Policy Accepted"
                                                        name="privacyPolicyAccepted"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select Privacy Policy Accepted",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="h-12 rounded-none"
                                                            placeholder="Privacy Policy Accepted"
                                                            getPopupContainer={(trigger) => trigger.parentElement} // Ensures correct dropdown positioning
                                                        >
                                                            <Select.Option value={true}>True</Select.Option>
                                                            <Select.Option value={false}>False</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}

                                                {/* Terms And Conditions Accepted */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Terms And Conditions Accepted"
                                                        name="termsAndConditionsAccepted"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select Terms And Conditions Accepted",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="h-12 rounded-none"
                                                            placeholder="Terms And Conditions Accepted"
                                                            getPopupContainer={(trigger) => trigger.parentElement} // Ensures correct dropdown positioning
                                                        >
                                                            <Select.Option value={true}>True</Select.Option>
                                                            <Select.Option value={false}>False</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}

                                                {/* Processor Identities */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        label="Processor Identities"
                                                        name="processorIdentities"
                                                        rules={[
                                                            {
                                                                type: "",
                                                                message: "Please Enter Processor Identities",
                                                                // warningOnly: true,
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please Enter Processor Identities",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder="Enter Processor Identities"
                                                            prefix={<CiMobile1 />}
                                                        />
                                                    </Form.Item>
                                                </Col> */}

                                                {/* Description */}
                                                {/* <Col xs={24} md={12}>
                                                    <Form.Item
                                                        label="Description"
                                                        name="description"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter a description",
                                                            },
                                                        ]}
                                                    >
                                                        <Input.TextArea
                                                            placeholder="Enter Description"
                                                            rows={2} // Adjust the height of the TextArea
                                                            className="rounded-none"
                                                        />
                                                    </Form.Item>
                                                </Col> */}


                                                {/* privacyPolicyAccepted */}
                                                {/* <Col xs={24} md={12} lg={12}>
                                                    <Form.Item
                                                        name="privacyPolicyAccepted"
                                                        valuePropName="checked"
                                                        dependencies={["privacyPolicyAccepted"]} // Ensures validation runs on change
                                                        rules={[
                                                            () => ({
                                                                validator(_, value) {
                                                                    return value
                                                                        ? Promise.resolve()
                                                                        : Promise.reject(new Error("You must accept the Privacy Policy"));
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Checkbox >
                                                            <span className="text-black cursor-pointer font-semibold">
                                                                I agree to the Privacy Policy.
                                                            </span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Col> */}


                                            </Row>

                                            <br />

                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="loginButton mainbtn"
                                                    loading={isBankDetailsUpdating || customerDataLoading}
                                                >
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}

export default ProfileDetails;
