import React, { useEffect, useState } from "react";
import { useCreatePaymentIntentMutation, useGetPublicKeyQuery } from "./../../api/vivamo";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { VIVAMO_URL } from "./../../config/constants/api";
import socket from "./../../socket";

const { hostname } = window.location;

const VivamoPayment = () => {
  // const { value: vivamo, loading, error, refetch } = useScript("https://js.vivamo.io/vivamo.js");
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [vivamo, setVivamo] = useState(null);
  const [createPaymentIntent, { isLoading }] = useCreatePaymentIntentMutation();
  const { data: vivamoKey } = useGetPublicKeyQuery({});

  useEffect(() => {
    // Load Vivamo script dynamically
    const script = document.createElement("script");
    script.src = VIVAMO_URL;
    script.async = true;
    script.onload = () => {
      if (window.Vivamo) {
        const vivamoInstance = window.Vivamo(vivamoKey, {
          environment: hostname.includes("realmoneydragon.io") ? "production" : "test",
        });
        setVivamo(vivamoInstance);
      } else {
        console.error("Vivamo script failed to load properly.");
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [vivamoKey]);

  useEffect(() => {
      socket.on("webhookSuccess", (data) => {
        if (data?.orderId === orderId && data?.status === "success" && data?.transactionType === "payment") {
          navigate("/payment-success", { state: { orderId } })
        }
      });
      socket.emit("registerOrder", orderId);
  
      return () => {
        socket.off("webhookSuccess");
      };
    }, [navigate, orderId]);

  useEffect(() => {
    if (vivamo) {
      createPaymentIntent({ orderId }).then(({ data, error }) => {
        if (data?.data?.clientSecretKey) {
          vivamo.createElements("#payment-form", data?.data?.clientSecretKey);
        }
        if (error) {

          if( error?.data?.message !== "Bank details not found"){
            swal("Error",error?.data?.message || "An error occured", "error");
            return
        }

          swal({
            title: error?.data?.message || "Error",
            icon: "warning",
            buttons: {
              cancel: {
                text: "Cancel",
                visible: true,
                className: "swal-button--cancel",
              },
              confirm: {
                text: "Add Bank Details",
                className: "swal-button--confirm",
              },
            },
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              navigate("/profile-details");
            }
          });
        }
      });
    }
  }, [vivamo, orderId, createPaymentIntent, navigate]);

  return (
    <>
      <div className="bg-white p-4">
        {isLoading ? (
          <div
            style={{
              height: 210,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            />
          </div>
        ) : null}
        <div id="payment-form" className="relative z-10"></div>
      </div>
    </>
  );
};

export default VivamoPayment;
