import {
  Button,
  Card,
  Col,
  Layout,
  Row,
  Typography
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { Background } from "../home";

const TIMER = 60;

function ForgetPasswordCode() {
  const { state } = useLocation();

  const navigate = useNavigate();

  let input_1 = useRef();

  let input_2 = useRef();

  let input_3 = useRef();

  // let input_4 = useRef();

  const [timeLeft, setTimeLeft] = useState(TIMER);
  const [isActive, setIsActive] = useState(true);

  let [codeData, setCodeData] = React.useState({
    input1: "",

    input2: "",

    input3: "",

    input4: "",
  });

  const resendCode = async (values) => {
    if (isActive) return
    let data = {
      email: state.email,
    };
    await Post(AUTH.emailCode, data)
      .then((response) => {
        if (response?.status) {
          startTimer();
          swal("Success", response.message, "success");
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        swal("Oops!", e?.response?.data?.message || e?.message, "error");
        console.log(":::;", e);
      });
  };


  const onFinish = async () => {
    let code =
      codeData.input1 + codeData.input2 + codeData.input3 + codeData.input4;

    if (
      codeData.input1 === "" ||
      codeData.input2 === "" ||
      codeData.input3 === "" ||
      codeData.input4 === ""
    ) {
      swal("Error", "Incomplete Code", "error");
    }
    await Post(AUTH.verifyCode, { code, email: state.email })
      .then((response) => {

        if (response?.status) {
          swal("Success", response?.message, "success");

          navigate("/passwordrecovery", {
            replace: true,
            state: { code, email: state.email },
          });
        } else {
          swal(
            "Oops!",
            response?.data?.message || response?.response?.data?.message,
            "error"
          );
        }
      })

      .catch((e) => {
        swal("Oops!", e?.response?.data?.message || e?.message, "error");

      });
  };

  function handleInputChange(name, value) {
    setCodeData({ ...codeData, [name]: value });
  }

  useEffect(() => {
    let timer;

    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setIsActive(false)
      setTimeLeft(TIMER)
    }

    // Clean up the timer on component unmount or when isActive changes
    return () => clearInterval(timer);
  }, [isActive, timeLeft]);

  const startTimer = () => {
    setIsActive(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <>
     <Background />
      <Layout className="banner">
      <Row className="flex justify-center items-center">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={24} md={10}>
            <Card>
              <div className="authFormBox checkout-form">
                <Row style={{ width: "100%", justifyContent: "center" }}>
                  <Col xs={20} md={20} className="formWrap">
                    
                    
                    <h3>FORGOT PASSWORD</h3>
                    <p className="mb-15">A four digit code has been set to your email.</p>
                    <div>
                    <Row gutter={15} className="flex flex-nowrap gap-2" style={{ width: "100%", justifyContent: "center" }}>
                          <input
                            autoFocus
                            type="text"
                            pattern="\d*"
                            maxLength="1"
                            className="codeInput"
                            onChange={(text) => {
                              input_1.focus();
                              handleInputChange(
                                "input1",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                   
                          <input
                            type="text"
                            maxLength="1"
                            className="codeInput"
                            ref={(val) => (input_1 = val)}
                            onChange={(text) => {
                              input_2.focus();
                              handleInputChange(
                                "input2",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                     
               
                          <input
                            type="text"
                            maxLength="1"
                            className="codeInput"
                            ref={(val) => (input_2 = val)}
                            onChange={(text) => {
                              input_3.focus();
                              handleInputChange(
                                "input3",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                            
                          <input
                            type="text"
                            maxLength="1"
                            className="codeInput"
                            ref={(val) => (input_3 = val)}
                            onChange={(text) => {
                              handleInputChange(
                                "input4",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                      </Row>
                    </div>
                    <br />
                    <Row  style={{ justifyContent: "end" }}>
                        <Col>
                        <p 
                        style={{
                          cursor: "pointer",
                          textDecoration: !isActive ? "underline" : "none",
                          color: isActive ? "#555" : "#AE802C",
                        }}
                        onClick={resendCode}
                        disabled={isActive}
                        >
                          {isActive ? `Resend Code in ${formatTime(timeLeft)}` : "Get Code"}
                          </p>
                        </Col>
                    </Row>
                    <br />
                 
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton mainbtn"
                      onClick={() => onFinish()}
                    >
                        VERIFY CODE
                      {/* {loading ? "Loading..." : "Continue"} */}
                    </Button>
                    <br />
                    
                    <Row style={{ width: "100%", justifyContent: "center" }}>
                      <Col xs={24} md={12}>
                       

                      <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      // textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <>
                    Back To  <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "#000",
                        }}
                      >
                       Login
                      </span>
                    </>
                  </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Row>
    </Layout>
    </>
    
  );
}

export default ForgetPasswordCode;
