import React, { useEffect, useState } from 'react';
import { useCreateDisbursementIntentMutation, useGetPublicKeyQuery } from './../../api/vivamo';
import { Button, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import { VIVAMO_URL } from "./../../config/constants/api";

const { hostname } = window.location;

const VivamoPayout = () => {
    // const { value: vivamo, loading, error, refetch } = useScript("https://js.vivamo.io/vivamo.js");
    const Navigate = useNavigate();
    const [form] = Form.useForm();
    const [createDisbursementIntent, { isLoading }] = useCreateDisbursementIntentMutation();
    const { data: vivamoKey } = useGetPublicKeyQuery({});
    const [vivamo, setVivamo] = useState(null);

    useEffect(() => {
        // Load Vivamo script dynamically
        const script = document.createElement("script");
        script.src = VIVAMO_URL;
        script.async = true;
        script.onload = () => {
            if (window.Vivamo) {
                const vivamoInstance = window.Vivamo(vivamoKey, {
                    environment: hostname.includes("realmoneydragon.io")
                        ? "production"
                        : "test",
                });
                setVivamo(vivamoInstance);
            } else {
                console.error("Vivamo script failed to load properly.");
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [vivamoKey]);



    const submit = (values) => {
        try {
            const { amount, note } = values;
            createDisbursementIntent({ amount: Number(amount), note })
                .then(({ data, error }) => {
                    if (data?.data?.clientSecretKey) {
                        // form.resetFields();
                        vivamo.createElements("#disbursement-form", data?.data?.clientSecretKey);
                    }
                    if (error) {

                        if (error?.data?.message !== "Bank details not found") {
                            swal("Error", error?.data?.message || "An error occured", "error");
                            return
                        }

                        swal({
                            title: error?.data?.message || "Error",
                            icon: "warning",
                            buttons: {
                                cancel: {
                                    text: "Cancel",
                                    visible: true,
                                    className: "swal-button--cancel"
                                },
                                confirm: {
                                    text: "Add Bank Details",
                                    className: "swal-button--confirm"
                                }
                            },
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                Navigate("/profile-details")
                            }
                        });
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Form name="basic" onFinish={submit} form={form}>
                <Form.Item
                    name="amount"
                    rules={[
                        { required: true, message: "Please input amount!" },
                    ]}
                >
                    <Input placeholder="Amount" />
                </Form.Item>

                <Form.Item name="note" rules={[{ required: false }]}>
                    <Input.TextArea placeholder="Notes" />
                </Form.Item>
                <Form.Item>
                    <Button
                        disabled={isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ minWidth: 119 }}
                    >
                        {isLoading ? (
                            <Spin
                                indicator={<LoadingOutlined spin />}
                                size="small"
                                style={{ color: "white" }}
                            />
                        ) : (
                            `Convert Coins`
                        )}
                    </Button>
                </Form.Item>
            </Form>
            <div className='bg-white p-4'>
                {isLoading ?
                    <div style={{ height: 210, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                    </div> :
                    null
                }
                <div id="disbursement-form" className="relative z-10"></div>
            </div>
        </>
    );
}

export default VivamoPayout;