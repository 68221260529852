import React, { useLocation, useNavigate } from 'react-router-dom';
import './PaymentSuccess.css'; // Optional CSS for styling
import { useGetOrderByIdQuery } from './../../api/orderApi';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = location.state ?? {};

  const { data: orderData } = useGetOrderByIdQuery(orderId);

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="innerpage">
      <div className="payment-success">
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
        <p>Your order is being processed, and you will receive a confirmation email soon.</p>

        <div className="success-details">
          <h3>Order Summary</h3>
          <p>Order Number: {orderData?._id}</p>
          <p>Total Amount: ${orderData?.totalPrice}</p>
        </div>

        <button className="back-home-btn" onClick={handleBackToHome}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
